<template>
  <b-container fluid class="main-container mt-3 mb-3">
    <b-row>
      <b-col>
        <NotificationsService />
        <div class="component-element">
          <component v-bind:is="currentComponent"></component>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import PayoutsForm from '../components/Tabs/Payouts/PayoutsForm.vue'
import Payouts from '../components/Tabs/Payouts/tablePayouts.vue'

import Invoices from '@/components/Tabs/Invoices/invoicesWrapper.vue'
import Projects from '@/components/Tabs/Projects/tableProjects.vue'
import LandingPage from '@/components/LandingPage.vue'
import NotificationsService from "@/components/NotificationsService/notificationsService.vue";

import LifeBroker from "../components/TabsLife/Sales/tableBroker.vue";
import LifeBroker_loans from "../components/TabsLife/Issued/tableBroker.vue";

import Payments from "../components/Tabs/Payments/tablePayments.vue"
import PaymentsAll from "../components/Tabs/Payments/tablePaymentsAll.vue"
import PaymentsChargebacks from "../components/Tabs/Payments/tablePaymentsChargebacks.vue"

export default {
  components: {
    NotificationsService,
    Payouts,
    PayoutsForm,
    Invoices,
    Projects,
    LandingPage,
    LifeBroker,
    LifeBroker_loans,
    Payments,
    PaymentsAll,
    PaymentsChargebacks,
  },
  data () {
    return {
    }
  },
  computed: {
    currentComponent () {
      let name = 'Payouts'
      if (Object.prototype.hasOwnProperty.call(this.$route, 'name')){
        name = this.$route.name
      }
      if (!this.isOpenedTabs(name)) {
        name = `LandingPage`
      }
      return name
    },
  },
  created () {
    this.$store.dispatch('loadV1ClientObject', this.$store.state.account.cid)
  },
  mounted () {

  },
  methods: {
    isOpenedTabs(tab = 'Analytics') {
      if (this.$store.state.account.clientDataReceived === false) {
        return false
      }
      const services = this.$store.state.account.clientData.services || []
      if (tab === 'Payouts' || tab === 'PayoutsForm') {
        return services.some(service => service.codename === 'payout_services' && service.options && service.options.some(option => option.is_selected))
      }
      if (tab === 'Payments' || tab === 'PaymentsAll' || tab === 'PaymentsChargebacks') {
        return services.some(service => service.codename === 'payin_services' && service.options && service.options.some(option => option.is_selected))
      }
      if (tab === 'LifeBroker' || tab === 'LifeBroker_loans') {
        return services.some(service => service.codename === 'credit_services' && service.options && service.options.some(option => option.is_selected))
      }
      return true
    },
  }
}
</script>

<style scoped>

</style>


import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/pages/home'
import Errors from '@/components/Errors'

import CommonWrapper from '@/pages/wrapper'
import StandardWrapper from '@/pages/stdWrapper'
import MobileWrapper from '@/pages/mobileWrapper'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    { path: '/sales',
      redirect: { name: 'Home' }
    },
    { path: '/login',
      redirect: { name: 'Home' }
    },
    {
      path: '/search',
      name: 'Search',
      component: StandardWrapper
    },
    {
      path: '/export',
      name: 'ExportPage',
      component: StandardWrapper
    },
    {
      path: '/project/add',
      name: 'ProjectAdd',
      component: StandardWrapper
    },
    {
      path: '/projects',
      name: 'Projects',
      component: MobileWrapper
    },
    {
      path: '/project/:arg1',
      name: 'ProjectEdit',
      component: StandardWrapper,
      props: (route) => ({ tab: route.query.tab })
    },
    {
      path: '/invoices/:page?',
      name: 'Invoices',
      component: MobileWrapper,
      props: (route) => ({ step: route.query.step })
    },

    {
      path: '/settings/profile',
      name: 'SettingsProfile',
      component: StandardWrapper
    },
    /*{
      path: '/settings/security',
      name: 'SettingsSecurity',
      component: StandardWrapper
    },*/
    {
      path: '/settings/requisites',
      name: 'SettingsRequisites',
      component: StandardWrapper
    },
    {
      path: '/settings/notifications',
      name: 'SettingsNotifications',
      component: StandardWrapper
    },
    {
      path: '/settings/requisites/addbankaccount',
      name: 'SettingsAddBankAccount',
      component: StandardWrapper
    },
    {
      path: '/rewards',
      name: 'Rewards',
      component: StandardWrapper
    },
    {
      path: '/analytics',
      name: 'Analytics',
      component: StandardWrapper
    },
    {
      path: '/payments',
      name: 'Payments',
      component: MobileWrapper
    },
    {
      path: '/payments/all',
      name: 'PaymentsAll',
      component: MobileWrapper
    },
    {
      path: '/payments/chargebacks',
      name: 'PaymentsChargebacks',
      component: MobileWrapper
    },
    {
      path: '/bindings',
      name: 'Bindings',
      component: StandardWrapper
    },
    {
      path: '/payouts',
      name: 'Payouts',
      component: MobileWrapper
    },
    {
      path: '/payouts/form/:mid?/:tid?/:step?',
      name: 'PayoutsForm',
      component: MobileWrapper,
      props: (route) => ({ mid: route.params.mid, tid: route.params.tid, step: route.params.step })
    },
    {
      path: '/pos',
      name: 'Pos',
      component: StandardWrapper
    },
    {
      path: '/loan_requests',
      name: 'LifeBroker',
      component: MobileWrapper
    },
    {
      path: '/loans',
      name: 'LifeBroker_loans',
      component: MobileWrapper
    },
    {
      path: '/partner-rewards',
      name: 'Partners',
      component: StandardWrapper
    },
    {
      path: '/receipts',
      name: 'Receipts',
      component: StandardWrapper
    },
    /*{ // Надо не забыть научить эту систему обрабатывать урлы типа /pay_merchant/nocode/modules/pay/395
      Оставим для последующего рефакторинга модулей.
      path: '/pay_merchant/nocode/:arg2',
      redirect: to => {
        if (Object.prototype.hasOwnProperty.call(to.params, 'arg2')){
          return '/nocode/'+to.params.arg2
        }
        return '/nocode/'
      },
    },*/


     { path: '/pay_merchant/:arg1',
      component: CommonWrapper,
      children: [
        { path: '', component: CommonWrapper },
        { path: ':arg2', component: CommonWrapper,
            children: [
                { path: '', component: CommonWrapper},
                { path: ':arg3', component: CommonWrapper,
                children: [
                    { path: '', component: CommonWrapper},
                    { path: ':mid', component: CommonWrapper }
                ]}
            ]
        }
      ]
    },

    { path: '/life_merchant/:arg1/:arg2',
      component: CommonWrapper,
      children: [
        { path: '', component: CommonWrapper },
        { path: ':actid', component: CommonWrapper },
      ]
    },
    {
      path: '*',
      name: 'errors',
      component: Errors
    }
  ]
})

<template>
  <div :class="['main-navbar', stickyTop ? 'sticky-top' : '']">
    <b-container fluid>
      <b-row><b-col>

    <d-navbar type="light" class="align-items-stretch p-0"  style="flex-wrap: nowrap">
      <!--<navbar-search />-->
      <NavbarSearchSimple />
      <NavbarClientInfo />
      <navbar-nav />
    </d-navbar>
    </b-col></b-row>
    </b-container>
  </div>
</template>

<script>
import NavbarSearch from './NavbarSearch.vue'
import NavbarSearchSimple from './NavbarSearchSimple.vue'
import NavbarClientInfo from './NavbarClientInfo.vue'
import NavbarNav from './NavbarNav.vue'
import NavbarToggle from './NavbarToggle.vue'

export default {
  components: {
    NavbarSearch,
    NavbarNav,
    NavbarToggle,
    NavbarSearchSimple,
    NavbarClientInfo,
  },
  props: {
    stickyTop: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.main-navbar {
  background: #F5F6F8 !important;
  .dropdown-menu {
    display: block;
  }
}


</style>

import axios from 'axios'
import store from '@/store'
import NotificationHTML from "@/components/NotificationsService/notificationHTML.vue";

const baseUrl = VUE_APP_API
const instance = axios.create({
  baseURL: baseUrl,
  paramsSerializer: {
    serialize: (params) => {
      // Генерим строку запроса из параметров, без дальнейшего перекодирования
      let result = ''
      let par = []
      Object.entries(params).forEach(entry => {
        par.push(entry[0] + '=' + entry[1])
      })
      result += par.join('&')
      return result
    }
  }

})
// Делаю axios доступным из модулей в store
store.$axios = instance
instance.interceptors.request.use(config => {
  const accessToken = store.state.account.accessToken
  config.headers['Accept'] = 'application/json, text/plain, */*'
  if (!config.headers['Content-Type'])
    config.headers['Content-Type'] = 'application/json';
  if (!config.headers['Authorization'])
    config.headers['Authorization'] = `Bearer ${accessToken}`
  return config
})
instance.interceptors.response.use(interceptorResponse, checkAndRenewBearerOnError)
export default instance


// Добавляем инстанс с доступом к апи пользователя - клиента.
const clientApiUrl = VUE_APP_IDENTITY
const instanceClient = axios.create({
  baseURL: clientApiUrl
})
// Добавляем
instanceClient.interceptors.request.use(config => {
  const accessToken = store.state.account.accessToken
  config.headers['Accept'] = 'application/json, text/plain, */*'
  config.headers['Content-Type'] = 'application/json'
  config.headers['Authorization'] = `Bearer ${accessToken}`
  return config
})
instanceClient.interceptors.response.use(interceptorResponse, checkAndRenewBearerOnError)
// Делаю axios доступным из модулей в store
store.$axiosClient = instanceClient
export {instanceClient}


// делаем отдельный инстанс для Дадаты
const instanceDadata = axios.create({})
// Добавляем токен
instanceDadata.interceptors.request.use(config => {
  const accessToken = VUE_APP_DADATA_TOKEN
  config.headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'Authorization': `Token ${accessToken}`
  }
  return config
})
// Делаю axiosDadata доступным из модулей в store
store.$axiosDadata = instanceDadata
export {instanceDadata}


function interceptorResponse(response) {
  return response
}

function checkAndRenewBearerOnError(error) {
  if (error.response) {
    if (error.response.status === 401) {
      if (!error.config.url.includes(VUE_APP_SECURE_APP)) {
        store.dispatch('authorizeUser')
      } else {
        window.location = VUE_APP_SECURE_APP + "auth_page/?reurl=" + window.location
      }
    }
    if (error.response.status === 403) {
      store._vm.$toast({
        component: NotificationHTML,
        props: {
          message: `По какой-то причине вам не хватает прав для операции. Пожалуйста, перелогиньтесь или обратитесь в поддержку, если ошибка повторится.<br>URL запроса: ${error.config.url}`,
        },
      }, {
        type: 'error',
      });
    }
    // в данном месте мы обрабатываем 404 ответ от VUE_APP_SECURE_APP - запрос на мастер-мерчанта.
    // валидный ответ может включать в себя 404 ответ, который мы должны обрабатывать как нормальный ответ.
    // поэтому возвращаем error.response для обработки в функции loadMasterMerchant
    if (error.response.status === 404) {
      return error.response
    }
    console.log('error.response', error.response)
  } else if (error.request) {
    console.log('error.request', error.request);
  } else {
    console.log('Error', error.message);
  }
  console.log('rejecting promise');
  return Promise.reject(error);
}

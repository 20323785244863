<template>
  <b-row>
    <b-col cols="12" xl="9">
      <NavTabs/>
      <b-card class="card-small with-nav-tabs">
      <Filters/>
      <div class="lifebroker_loans">
        <PagerComponent/>
      </div>
      <Loader v-if="loadingData" :height="600" :size="3" />
      <div class="main-table-wrapper" v-else>
        <b-table striped hover selectable select-mode="single" class="text-nowrap" :fields="fields" :items="dataTable" @row-clicked="selectItem">
          <template v-slot:head()="data">
            <span v-html="data.field.label"></span>
          </template>
          <template v-slot:head(purchase_amount)="data">
            Сумма перечисления <i v-b-tooltip.hover title="Сумма, номер и дата платежного поручения по заявке отображаются после формирования на стороне Mandarin" class="fas fa-info-circle"></i><br>
            Номер и дата п/п
          </template>

          <template v-slot:cell(application_id)="data">
            <clipboard v-if="data.item.customer.fio" :data="data.item.customer.fio"/>&nbsp;{{data.item.customer.fio}}<br>
            <clipboard :data="data.item.contract_number"/>&nbsp;{{data.item.contract_number}}
          </template>
          <template v-slot:cell(customer)="data">
            <clipboard v-if="data.item.customer.phone" :data="data.item.customer.phone"/>&nbsp;{{data.item.customer.phone}}<br>
            <clipboard v-if="data.item.customer.email" :data="data.item.customer.email"/>&nbsp;{{data.item.customer.email}}
          </template>
          <template v-slot:cell(purchase_amount)="data">
            <span>{{ (data.item.extra.settlement_amount!==extraFieldsDefault)?properM(data.item.extra.settlement_amount):extraFieldsDefault}} <span v-if="data.item.extra.settlement_amount!==extraFieldsDefault" v-html="currency(data.item.currency_id)"></span></span>
            <br>
            <span>{{ (data.item.extra.settlement_number!==extraFieldsDefault)?'п/п №'+data.item.extra.settlement_number:''}}{{ (data.item.extra.settlement_date!==extraFieldsDefault)?' от '+properD(data.item.extra.settlement_date):'' }}</span>
          </template>
          <template v-slot:cell(status)="data">
            <span>{{properDT(data.item.created)}}</span>
            <br>
            <span v-html="getStatusBadge(data.item.status.status_code)"></span>
          </template>
          <template v-slot:cell(actions)="data">
            <b-dropdown text="Действия" variant="primary" right ref="dropdown">
              <b-dropdown-item disabled>Запросить возврат по заявке</b-dropdown-item>
              <b-dropdown-item disabled>Загрузить заявление на возврат</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div v-if="!haveResults" style="min-height: 400px; text-align: center; padding-top: 150px;">
          Данные за указанный период с учетом выбранных фильтров отсутствуют.
        </div>
      </div>
      </b-card>
    </b-col>
    <b-col cols="12" xl="3" class="fixed-extend desktop-layer">
      <Extend :item="extendItem"/>
    </b-col>
  </b-row>
</template>

<script>
import PagerComponent from '@/components/Pager/pagerComponent'
import Filters from './Filters/Filters'
import getStatusBadge from '@/configs/filtersTables/labels_lifeBroker'
import NavTabs from './../navTabs'
import Extend from './extendBroker'

export default {
  components: {
    PagerComponent,
    Filters,
    NavTabs,
    Extend
  },
  data(){
    return{
      extraFieldsDefault: 'Ожидается',
      extendItem: false,
      filters_config: null,
      transaction: null,
      fields: [
        {
          key: 'application_id',
          label: 'ФИО клиента<br>Номер заявки'
        },
        {
          key: 'customer',
          label: 'Моб. телефон клиента<br>E-mail клиента'
        },
        {
          key: 'purchase_amount',
          label: 'Сумма перечисления' // Компоненты вроде v-b-tooltip не процессятся в v-html, а обрабатываются напрямую в шаблоне cell
        },
        {
          key: 'status',
          label: 'Дата создания заявки<br>Текущий статус'
        },
        {
          key: 'actions',
          label: ''
        }
      ]
    }
  },
  computed:{
    dataTable: function () {
      let dataTable = this.$store.state.lifeBroker.transactions
      dataTable = dataTable.map(item => {
          let extraFieldDefault = this.extraFieldsDefault
          let extraDefault = {settlement_number: extraFieldDefault, purchase_amount: extraFieldDefault, settlement_amount: extraFieldDefault, settlement_date: extraFieldDefault, lead_reward_fee_rate: extraFieldDefault, lead_reward_fee_amount: extraFieldDefault}
          let clone_extra = {}
          if (item.extra !== null) {
              for (let key in extraDefault) {
                if (!item.extra.hasOwnProperty(key)) clone_extra[key] = extraDefault[key]; else {
                    if (key === 'purchase_amount' || key === 'settlement_amount') clone_extra[key] = Number(item.extra[key]); else clone_extra[key] = item.extra[key];
                }
              }
          } else clone_extra = extraDefault
          return {
            ...item,
            customer: (item.customer === null)?
            {fio: '', phone: '', email: ''}:
                    Object.assign(item.customer, {fio: item.customer.last_name + ' ' + item.customer.name + ' ' + item.customer.patronymic}),
            status: (item.status === null)?
            {status_code: 'A0', merch_status_description: null}:
                    item.status,
            merch_status_description: this.getMerchStatusDescription(item),
            currency_id: (item.hasOwnProperty('currency_id'))?item.currency_id:643,
            purchase_amount: Number(item.purchase_amount),
            cr_ras: (item.max_discount.calc_discount7Seconds)?'Рассрочка':'Кредит',
            offer: (item.offer === null)?
            {settlement_amount: null, lender_name: null, lender_agreement_number: null, maturity_in_months: null}:
                    item.offer,
            order: (item.order === null)?
            {settlement_order_id: null, order_description: null}:
                    item.order,
            extra: clone_extra
          }
      })
      return dataTable
    },
    loadingData(){
      return !this.$store.state.pagerStd.apiResponseReceived
    },
    haveResults(){
      if (Array.isArray(this.dataTable) && this.dataTable.length>0){
        return true
      }
      return false
    }

  },
  watch: {
    loadingData: function (newValue, oldValue) {
      if (newValue){
        // Если мы грузим данные - чистим extendItem
        this.extendItem = false

      }
    }
  },
  methods:{
    getMerchStatusDescription: function(item) {
      if (!item || !item.status || !this.filters_config || !this.filters_config.status || !this.filters_config.status.codes) {
        return null;
      }
      const codeInfo = this.filters_config.status.codes[item.status.status_code];
      return codeInfo ? codeInfo[1] : null;
    },
    selectItem(item, index){
      item.extStatus = this.filters_config.status.codes[item.status.status_code]
      this.extendItem = item
    },
    getStatusBadge(status){
      return getStatusBadge(this.filters_config, status)
    }
  },
  created(){
    // Чтобы остатки фильтров не оставались после предыдущих страниц, очищаем фильтры. Делать это надо до установки новых.
    this.$store.commit('filtersStd/clearFilters')
    // Устанавливаем, какой из конфигов фильтров из config/filtersTables используется в этой таблице.
    this.$store.commit('filtersStd/setActiveFilterConfig', 'filtersLifeBroker', { root: true })
  },
  mounted () {
    this.filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
    // Устанавливаем нейспейс для пейджера в этой таблице.
    this.$store.commit('pagerStd/updateActiveTableNamespace', 'lifeBroker', { root: true })
    // после установки таблицы апдейтим ее нынешнее значение. К этому моменту все фильтры должны быть установлены.
    this.$store.dispatch('pagerStd/updateCurrentTableState', 'default')
  },
}
</script>

<style scoped>
    table >>> .b-table-row-selected {
      background-color: rgba(189, 214, 320, 1) !important;
    }
</style>

<template>
  <div class="w-100">
    <a class="back-block d-flex align-items-center" @click.prevent="goBack">
      <img src="@/assets/images/apple-keyboard-control.png" alt="Go back" width="24" height="24">
      <span class="back-link pl-2">Вернуться</span>
    </a>
    <div class="settings-block">
      <div class="fio">{{ item.customer.fio | formatFIO }}</div>
      <div v-if="isLoadingSendOk" class="d-flex flex-column align-items-center justify-content-center w-100 my-5">
        <img class="mb-4" src="@/assets/images/icon_ok.svg" alt="Ok">
        <div class="text-center mb-5 fio">
          Документы успешно отправлены на проверку!
        </div>
      </div>
      <div v-else>
        <div class="title mb-4">Загрузка документов на проверку</div>
        <div v-if="isDownloading" class="d-flex justify-content-center align-content-center upload-container-spinner">
          <b-spinner class="m-auto"></b-spinner>
        </div>
        <div v-else class="dropzone upload-container" @dragover.prevent @drop="onDrop" @click="openFilePicker">
          <div class="text-center py-5">
            <img src="@/assets/images/tray-arrow-down.svg" class="mb-3"  alt="upload"/>
            <p class="text mb-2">Перетащите файлы в поле или нажмите на эту область для загрузки файлов</p>
            <p class="text-muted">Допустимые форматы файла: pdf, jpg, jpeg, png. Допустимый размер файла – до 10 мб.</p>
          </div>
          <input type="file" multiple @change="onChange" ref="fileInput" hidden />
        </div>
        <div v-if="isLoading" class="d-flex justify-content-center mt-5">
          <b-spinner></b-spinner>
        </div>
        <b-table v-else-if="files.length" striped :fields="fields" :items="files" class="mt-5 files-table">
          <template v-slot:cell(preview)="data">
            <img src="@/assets/images/icon_success.svg" alt="Preview" title="Нажмите, что бы открыть файл">
          </template>
          <template v-slot:cell(delete)="row">
            <a @click.prevent="deleteFile(row.item)" v-if="!row.item.isDeleting">
              <img class="delete-icon" src="@/assets/images/icon_delete.png" alt="Delete">
            </a>
            <div v-else class="spinner">
            </div>
          </template>
          <template v-slot:cell(Size)="row">
            {{ row.item.Size }} Kb
          </template>
          <template v-slot:cell(UploadDate)="row">
            {{ properDT(row.item.UploadDate) }}
          </template>
          <template v-slot:cell(FileName)="row">
            <a @click.prevent="showImg(row.item)" v-if="!row.item.isLoading">
              {{ row.item.FileName }}
            </a>
            <div v-else class="text-green">
              Файл загружается...
            </div>
          </template>
        </b-table>
        <template>
          <b-btn class="send-btn my-4" variant="primary" @click="sendToVerification" :disabled="isLoadingSend || files.length === 0">
            <span v-if="!isLoadingSend">Отправить на проверку</span>
            <b-spinner v-else small></b-spinner>
          </b-btn>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileUploader',
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingSend: false,
      isLoadingSendOk: false,
      isDownloading: false,
      DocumentTypeId: '00000000-0000-0000-0000-000000000000',
      fields: [
        { key: 'preview', label: '' },
        { key: 'FileName', label: 'Наименование' },
        { key: 'Size', label: 'Размер' },
        { key: 'UploadDate', label: 'Время загрузки' },
        { key: 'delete', label: '' },
      ],
      files: []
    };
  },
  methods: {
    goBack() {
      this.$emit('closeUploadMode');
    },
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(file) {
      const validFileExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!validFileExtensions.includes(fileExtension)) {
        this.$toast.error(`Invalid file format for ${file.name}. Allowed formats are pdf, jpg, jpeg, png.`);
        return;
      }
      const maxFileSize = 10 * 1024 * 1024;
      if (file.size > maxFileSize) {
        this.$toast.error(`File size for ${file.name} should not exceed 10 MB.`);
        return;
      }
      this.isDownloading = true;
      try {
        await this.$store.dispatch('lifeBroker/uploadScannedDocument', {
          applicationId: this.item.application_id,
          file: file,
          fileName: file.name,
          documentTypeId: null
        });
      } catch (error) {
        this.$toast.error('Failed to upload file', error);
      } finally {
        this.isDownloading = false;
      }
    },
    async updateFileList() {
      this.isLoading = true;
      const response = await this.$store.dispatch('lifeBroker/downloadListScanDocs', this.item.application_id)
      if(response.data) {
        this.files = response.data.map(doc => {
          return doc.Pages.map(page => ({
            ...page,
            Size: (page.Size / 1024).toFixed(2),
            preview: true,
            delete: true,
            isDeleting: false,
            isLoading: false,
            DocumentTypeId: doc.DocumentTypeId,
            DocumentTypeName: doc.DocumentTypeName,
          }));
        }).flat();
      }
      this.isLoading = false;
    },
    async showImg(item) {
      item.isLoading = true;
      try {
        const fileBlob = await this.$store.dispatch('lifeBroker/getScannedDocument', item.StreamId);
        const fileUrl = URL.createObjectURL(fileBlob);
        window.open(fileUrl, '_blank');
      } catch (error) {
        this.$toast.error(`Failed to open file ${item.FileName}: ${error}`, { timeout: 0 });
      }
      item.isLoading = false;
    },
    async deleteFile(file) {
      file.isDeleting = true;
      try {
        console.log(file.StreamId)
        await this.$store.dispatch('lifeBroker/deleteScannedDocument', {
          StreamId: file.StreamId
        });
        await this.updateFileList();
      } catch (error) {
        this.$toast.error('Failed to delete file', error);
      }
      file.isDeleting = false;
    },
    async initializeFileList() {
      try {
        await this.updateFileList();
      } catch (error) {
        console.log('error in promise', error);
      } finally {

      }
    },
    async onChange(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        await this.handleFileUpload(files[i]);
      }
      this.$refs.fileInput.value = '';
      await this.updateFileList();
    },
    async onDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        await this.handleFileUpload(files[i]);
      }
      await this.updateFileList();
    },
    async sendToVerification() {
      this.isLoadingSend = true;
      try {
        await this.$store.dispatch('lifeBroker/sendToVerification', this.item.application_id);
        // this.isLoadingSendOk = true;
        this.goBack();
        this.$toast.success(`Ваши файлы успешно отправлены на проверку. Your files have been successfully sent for review.`);
      } catch (error) {
        // console.log('Errors', error);
        let errorMessage = 'Unknown error';
        if (error.response && error.response.data) {
          errorMessage = error.response.data.Message || error.response.data.errors || 'Unknown error';
        }
        this.$toast.error(`Failed to send for verification: ${errorMessage}`, {
          timeout: 0
        });
      } finally {
        this.isLoadingSend = false;
      }
    },
  },
  async mounted() {
    await this.initializeFileList();
  },
};
</script>

<style scoped>
::v-deep .files-table tr:hover {
  cursor: default;
}
::v-deep .files-table tr:hover a {
  cursor: pointer;
}
::v-deep .files-table th:nth-child(1),
::v-deep .files-table td:nth-child(1),
::v-deep .files-table th:nth-child(5),
::v-deep .files-table td:nth-child(5){
  width: 50px;
}
::v-deep .files-table th:nth-child(2),
::v-deep .files-table td:nth-child(2) {
  width: auto;
}
::v-deep .files-table th:nth-child(3),
::v-deep .files-table td:nth-child(3),
::v-deep .files-table th:nth-child(4),
::v-deep .files-table td:nth-child(4) {
  width: 150px;
}
.delete-icon {
  max-height: 16px;
}
.text-green {
  color: #44C846;
}
.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000;
  border-radius: 50%;
  width: 24px !important;
  height: 24px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.upload-container-spinner {
  height: 198px;
}
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #3988FF;
  border-radius: 4px;
  padding: 25px 0;
  height: 198px;
  cursor: pointer;
}
.upload-container:hover {
  background-color: #f8f9fa;
}
.fio {
  font-size: 16px;
  font-weight: bold;
}
.title {
  font-size: 16px;
  color: #357BF6;
  font-weight: bold;
}
.back-block:hover {
  cursor: pointer;
}
.back-block:hover .back-link {
  color: #000;
}
.back-link {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}
.text {
  font-size: 16px;
}
.text-muted {
  font-size: 14px;
}
.settings-block {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;
  gap: 20px;
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}
.settings-block div {
  width: 100%;
}
.send-btn {
  width: 100%;
}
</style>

import { render, staticRenderFns } from "./tablePaymentsAll.vue?vue&type=template&id=caa79c34&scoped=true"
import script from "./tablePaymentsAll.vue?vue&type=script&lang=js"
export * from "./tablePaymentsAll.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "caa79c34",
  null
  
)

export default component.exports
<template>
  <div class="form-payout">
    <div v-if="step !== 'checkPhone'" class="w-100 mb-4">
      <a href="#" @click.prevent="back" class="">
        <i class="fas fa-arrow-left mr-2"></i>Назад
      </a>
    </div>
    <!-- Ввод номера телефона-->
    <div
      v-if="step === 'checkPhone' || step === 'enterNext' || step === 'enterPassportNumber' || step === 'enterSmsCode' || step === 'enterRegisterSmsCode'">
      <b-form-group class="mb-3">
        <label for="phoneNumber">Номер телефона получателя приемо-сдаточного акта (ПСА)</label>
        <vue-tel-input id="phoneNumber" :inputOptions="phoneGlobalOptions" class="form-control my-2"
                       v-model="phoneNumberPsa" :disabled="step !== 'checkPhone'"></vue-tel-input>
        <span v-if="!phoneNumberPsaValid" class="text-info">Данного номера нет в системе, нажмите "продолжить", чтобы создать сдатчика</span>
        <span v-if="!phoneNumberPsaRegister" class="text-danger mt-1">Ошибка регистрации номера</span>
      </b-form-group>

      <b-button v-if="step === 'enterNext'" @click="onNext" variant="primary" class="w-100" :disabled="isLoadingData">
        <b-spinner v-if="isLoadingData" small></b-spinner>
        Продолжить
      </b-button>
      <b-button v-else-if="step === 'checkPhone'" @click="onCheckPhoneNumber" variant="primary" class="w-100"
                :disabled="isLoadingData">
        <b-spinner v-if="isLoadingData" small></b-spinner>
        {{ !phoneNumberPsaValid ? 'Продолжить' : 'Проверить номер' }}
      </b-button>
    </div>

    <!-- ФИО и ввод номера паспорта если номер в БД есть-->
    <div v-if="step === 'enterPassportNumber' || step === 'enterSmsCode'">
      <div class="mb-4">
        <span class="font-weight-bold pr-2">Пользователь</span>
        <span>{{ fullName }}</span>
      </div>

      <b-form-group class="mb-3">
        <label for="passportNumber">Номер паспорта (последние 6 цифр)</label>
        <b-input id="passportNumber" v-model="passportNumber" :readonly="step === 'enterSmsCode'"
                 v-mask="maskNumber"></b-input>
        <span v-if="!passportNumberPsaValid"
              class="text-danger">Номер паспорта неверный, проверьте и повторите попытку</span>
      </b-form-group>

      <b-button v-if="step === 'enterPassportNumber'" @click="onSubmitPassportNumber" variant="primary" class="w-100"
                :disabled="isLoadingData || !/^\d{6}$/.test(this.passportNumber)">
        <b-spinner v-if="isLoadingData" small></b-spinner>
        Подтвердить номер паспорта
      </b-button>
    </div>

    <!-- Ввод кода из СМС и показ паспортных данных-->
    <div v-if="step === 'enterSmsCode' || step === 'enterRegisterSmsCode'">
      <b-form-group class="mb-3">
        <label for="smsCode">Код из SMS</label>
        <b-input id="smsCode" v-model="smsCode"></b-input>
        <span v-if="!smsPsaValid || !smsRegisterPsaValid" class="text-danger">Код из СМС неверный, проверьте и повторите попытку</span>
      </b-form-group>

      <b-button @click="onVerifySmsCode" variant="primary" class="w-100" :disabled="isLoadingData">
        <b-spinner v-if="isLoadingData" small></b-spinner>
        Подтвердить код
      </b-button>
    </div>

    <!-- Форма ввода паспортных данных если номера нет в БД-->
    <div v-if="step === 'enterPassportData'" class="passport-data-form">
      <h4 class="mt-2">Данные физ лица</h4>
      <b-form id="PSAForm">
        <b-form-group label="ФИО" class="mb-3" label-for="fio-input">
          <DadataSuggestion
            id="fio-input"
            v-model="userData.fio"
            type="fio"
            placeholder="Введите ФИО"
            :handleParsedData="handleFioData"
          />
        </b-form-group>

        <b-row>
          <b-col cols="12" md="3">
            <b-form-group label="ИНН" label-for="inn-input">
              <b-form-input id="inn-input" v-model="userData.inn" maxlength="12"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group label="Гражданство" label-for="citizenship-select">
              <b-form-select id="citizenship-select" v-model="userData.citizenship"
                             :options="['РФ', 'Другое']"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Серия номер паспорта" label-for="passport-combined-input"
                          v-if="step === 'enterPassportNumber' || step === 'enterPassportData'">
              <b-form-input id="passport-combined-input"
                            ref="passport-combined-input"
                            v-model="userData.passportCombined"
                            v-mask="maskSeriesNumber"
                            placeholder="____ ______">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Кем выдан" class="mb-3" label-for="division-name-input">
              <DadataSuggestion
                id="division-name-input"
                v-model="userData.divisionName"
                type="fms_unit"
                :handleParsedData="handleDivisionData"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Код подразделения" class="mb-3" label-for="division-code-input">
              <b-form-input id="division-code-input" v-model="userData.divisionCode"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Место регистрации" class="mb-3" label-for="registration-place-input">
          <DadataSuggestion
            id="registration-place-input"
            v-model="userData.registrationPlace"
            type="address"
          />
        </b-form-group>

        <!-- Форма ввода данных для ПСА-->
        <h4 class="mt-4">Формирование ПСА</h4>
        <b-form-group label="Общий вид вторсырья" class="mb-3" label-for="metal-type-select">
          <b-form-input :id="`metal-type-select`" v-model="metalType"></b-form-input>
        </b-form-group>

        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Основные особенности" label-for="main-features-input">
              <b-form-input id="main-features-input" v-model="mainFeatures"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Подтверждающий документ" label-for="confirming-doc-select">
              <b-form-input id="confirming-doc-select" v-model="confirmingDocument"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Номер ПСА" label-for="number-document-input">
              <b-form-input id="number-document-input" v-model="numberDocument"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Дата создания" label-for="creation-date-input">
              <b-form-datepicker id="creation-date-input" v-model="creationDate" placeholder="Выберите дату">
              </b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Множество позиций -->
        <hr/>
        <div v-for="(position, index) in positions" :key="index">
          <b-row>
            <b-col :cols="index > 0 ? 11 : 12">
              <b-form-group label="Краткое наименование лома" label-for="`scrap-short-name-input-${index}`">
                <b-form-input :id="`scrap-short-name-input-${index}`" v-model="position.scrapShortName"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col v-if="index > 0" cols="1" class="align-content-center">
              <label for="icon_delete" class="text-white">Del</label>
              <div style="height: calc(2.425rem + 2px); cursor: pointer;">
                <img :src="require('@/assets/images/icon_delete.png')" alt="delete icon" class=""
                     @click.prevent="positions.splice(index, 1)"/>
              </div>
            </b-col>
          </b-row>

          <b-form-group label="Единица измерения" label-for="`measurement-unit-select-${index}`">
            <b-form-select :id="`measurement-unit-select-${index}`" v-model="position.measurementUnit"
                           :options="['Кг', 'Тн']"></b-form-select>
          </b-form-group>

          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Вес брутто" label-for="`gross-weight-input-${index}`">
                <b-form-input type="number" step="0.001" :id="`gross-weight-input-${index}`"
                              v-model="position.grossWeight"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Вес тары" label-for="`tare-weight-input-${index}`">
                <b-form-input type="number" step="0.001" :id="`tare-weight-input-${index}`"
                              v-model="position.tareWeight"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Засор, %" label-for="`contamination-input-${index}`">
                <b-form-input type="number" :id="`contamination-input-${index}`"
                              v-model="position.contamination"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Вес нетто" label-for="`net-weight-input-${index}`">
                <b-form-input type="number" step="0.001" :id="`net-weight-input-${index}`"
                              :value="position.netWeight" disabled></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Цена" label-for="`price-input-${index}`">
                <b-form-input type="number" :id="`price-input-${index}`" v-model="position.price"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Сумма" label-for="`total-input-${index}`">
                <b-form-input type="number" :id="`total-input-${index}`" :value="calculateTotal(index)"
                              disabled></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <hr/>
        </div>

        <b-col cols="12" class="mb-4">
          <a @click.prevent="addPosition" href="#" class="w-100">
            + Добавить позицию
          </a>
        </b-col>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Номер транспортного средства *" label-for="number-auto-input">
              <b-form-input id="number-auto-input" v-model="numberAuto"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Марка транспортного средства *" label-for="model-auto-input">
              <b-form-input id="model-auto-input" v-model="modelAuto"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            * необязатьельное поле
          </b-col>
        </b-row>
      </b-form>

      <b-button @click="onSubmitPassportData" variant="primary" class="w-100 mt-4" :disabled="isLoadingData">
        <b-spinner v-if="isLoadingData" small></b-spinner>
        Перейти к выплате
      </b-button>

    </div>
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input';
import {VueMaskDirective} from 'v-mask';
import DadataSuggestion from "@/components/Elements/DadataSuggestion.vue";

export default {
  name: "StepsPSA",
  emits: ['goBack', 'goNextStep'],
  components: {
    DadataSuggestion,
    VueTelInput
  },
  directives: {
    mask: VueMaskDirective,
  },
  data: () => ({
    isNewPsa: true,
    maskSeriesNumber: '#### ######',
    maskNumber: '######',
    token: '',
    access_token: '',
    attempt_id: '',
    passportNumber: '',
    smsCode: '',
    passportData: '',
    phoneGlobalOptions: {
      autofocus: true,
      showDialCode: true,
      placeholder: '+7 999 999 99 99'
    },
    phoneNumberPsa: '',
    phoneNumberPsaValid: true,
    phoneNumberPsaRegister: true,
    passportNumberPsaValid: true,
    smsPsaValid: true,
    smsRegisterPsaValid: true,
    isLoadingDataPsa: false,
    step: 'checkPhone',
    fullName: '...',
    userData: {
      fio: '',
      last_name: '',
      first_name: '',
      patronymic: '',
      inn: '',
      citizenship: 'РФ',
      passportCombined: '',
      passportSeries: '',
      passportNumber: '',
      divisionName: '',
      divisionCode: '',
      registrationPlace: '',
    },
    positions: [
      { // Инициализация первой позиции по умолчанию
        scrapShortName: '',
        measurementUnit: '',
        grossWeight: null,
        tareWeight: null,
        contamination: null,
        netWeight: null,
        price: null,
        total: null
      }
    ],
    metalType: '',
    mainFeatures: '',
    confirmingDocument: '',
    numberDocument: '',
    creationDate: null,
    numberAuto: '',
    modelAuto: '',
  }),
  computed: {
    isLoadingData() {
      return this.$store.state.payLender.loadingData;
    },
  },
  watch: {
    'positions': {
      handler(newVal, oldVal) {
        newVal.forEach((position, index) => {
          this.$set(position, 'total', this.calculateTotal(index));
          this.$set(position, 'netWeight', position.grossWeight - position.tareWeight);
        });
      },
      deep: true
    },
    'userData.passportCombined': function (newValue, oldValue) {
      if (newValue) {
        const [series, number] = newValue.split(' ');
        this.userData.passportSeries = series;
        this.userData.passportNumber = number;
      }
    },
    step: async function (newValue, oldValue) {
      if (newValue) {
        if (newValue === 'enterPassportData' && this.isNewPsa) {
          this.clearForm()
        }
        if (this.access_token && this.isNewPsa) {
          try {
            const result = await this.$store.dispatch('payLender/getPsaData', this.access_token);
            if (result.success) {
              this.userData.fio = `${result.passport.last_name} ${result.passport.first_name} ${result.passport.patronymic}`;
              this.userData.inn = result.passport.inn;
              this.userData.citizenship = result.passport.citizenship || 'РФ';
              this.userData.passportSeries = result.passport.series || '0000';
              this.userData.passportNumber = result.passport.number || '000000';
              this.$refs['passport-combined-input'].value = `${this.userData.passportSeries || '0000'} ${this.userData.passportNumber || '000000'}`;
              this.userData.divisionName = result.passport.issuer_name;
              this.userData.divisionCode = result.passport.issuer_code;
              this.userData.registrationPlace = result.passport.registration_address.full_address;
            } else {
              // this.$toast.error('Ошибка при получении данных пользователя');
              console.error('Ошибка при получении данных пользователя');
            }
          } catch (error) {
            // this.$toast.error('Ошибка при получении данных пользователя');
            console.error('Ошибка при получении данных пользователя');
          }
        }
      }
      if (newValue && newValue === 'enterPassportData') {
        this.$nextTick(() => {
          const form = this.$el.querySelector('#PSAForm');
          if (form) {
            this.setupFocusHandlers();
          }
        });
      } else if (oldValue && oldValue === 'enterPassportData') {
        this.$nextTick(() => {
          const form = this.$el.querySelector('#PSAForm');
          if (form) {
            this.deleteFocusHandlers();
          }
        });
      }
    }
  },
  methods: {
    handleFioData(data) {
      this.userData.last_name = data.surname;
      this.userData.first_name = data.name;
      this.userData.patronymic = data.patronymic;
    },
    handleDivisionData(data) {
      this.userData.divisionCode = data.code;
    },
    calculateTotal(index) {
      const position = this.positions[index];
      const netWeight = position.grossWeight - position.tareWeight;
      const contaminatedAmount = 1 - position.contamination / 100;
      return (netWeight * position.price * contaminatedAmount).toFixed(2);
    },
    clearForm() {
      this.userData = {
        fio: '',
        last_name: '',
        first_name: '',
        patronymic: '',
        inn: '',
        citizenship: '',
        passportNumber: '',
        divisionName: '',
        divisionCode: '',
        registrationPlace: '',
      }
      this.positions = [
        {
          scrapShortName: '',
          measurementUnit: '',
          grossWeight: null,
          tareWeight: null,
          contamination: null,
          netWeight: null,
          price: null,
          total: null
        }
      ]
      this.passportNumber = ''
      this.smsCode = ''
      this.metalType = ''
      this.mainFeatures = ''
      this.confirmingDocument = ''
      this.numberDocument = ''
      this.creationDate = null
      this.numberAuto = ''
      this.modelAuto = ''
    },
    async onNext() {
      this.isLoadingDataPsa = true;
      this.phoneNumberPsaValid = true;
      try {
        const result = await this.$store.dispatch('payLender/registerPsaNumber', `${this.phoneNumberPsa.replaceAll(' ', '')}`);
        if (result.success) {
          this.token = result.token;
          this.attempt_id = result.attempt_id;
          this.step = 'enterRegisterSmsCode';
          this.isLoadingDataPsa = false;
          this.phoneNumberPsaRegister = true;

        } else {
          this.step = 'enterNext';
          this.token = '';
          this.attempt_id = '';
          this.isLoadingDataPsa = false;
          this.phoneNumberPsaRegister = false;
        }
      } catch (error) {
        this.$toast.error('Ошибка при регистрации номера телефона');
        this.isLoadingDataPsa = false;
        this.phoneNumberPsaRegister = false;
      }
    },
    async onCheckPhoneNumber() {
      this.isLoadingDataPsa = true;
      try {
        const result = await this.$store.dispatch('payLender/validatePsaNumber', `${this.phoneNumberPsa.replaceAll(' ', '')}`);
        if (result.success) {
          this.token = result.token;
          this.step = 'enterPassportNumber';
          this.fullName = result.fio || '...';
          this.isLoadingDataPsa = false;
          this.phoneNumberPsaValid = true;
        } else {
          this.step = 'enterNext';
          this.token = '';
          this.fullName = '...';
          this.isLoadingDataPsa = false;
          this.phoneNumberPsaValid = false;
        }
      } catch (error) {
        this.$toast.error('Ошибка при проверке номера телефона');
        this.isLoadingDataPsa = false;
        this.phoneNumberPsaValid = false;
      }
    },
    async onSubmitPassportNumber() {
      try {
        const result = await this.$store.dispatch('payLender/validatePsaPassportNumber', {
          token: this.token,
          number: this.passportNumber,
          phone: this.phoneNumberPsa.replaceAll(' ', '')
        });
        if (result.success) {
          this.attempt_id = result.attempt_id;
          this.token = result.token;
          this.step = 'enterSmsCode';
          this.passportNumberPsaValid = true;
        } else {
          this.attempt_id = '';
          // this.token = '';
          this.passportNumberPsaValid = false;
        }
        this.isLoadingDataPsa = false;
      } catch (error) {
        this.attempt_id = '';
        // this.token = '';
        this.$toast.error('Ошибка при проверке номера паспорта');
        this.isLoadingDataPsa = false;
        this.passportNumberPsaValid = false;
      }
    },
    async onVerifySmsCode() {
      this.isLoadingDataPsa = true;
      try {
        const response = await this.$store.dispatch('payLender/confirmPsaSMS', {
          token: this.token,
          attempt_id: this.attempt_id,
          code: this.smsCode,
          phone: this.phoneNumberPsa.replaceAll(' ', '')
        });
        if (response.success) {
          this.access_token = response.access_token;
          this.$store.commit('payLender/SET_PSA_ACCESS_TOKEN', response.access_token);
          this.step = 'enterPassportData';
          this.smsPsaValid = true;
          this.smsRegisterPsaValid = true;
        } else {
          this.access_token = '';
          this.$store.commit('payLender/SET_PSA_ACCESS_TOKEN', '');
          this.smsPsaValid = false;
          this.smsRegisterPsaValid = false;
        }
        this.isLoadingDataPsa = false;
      } catch (error) {
        this.$toast.error('Ошибка при проверке кода из СМС');
        this.isLoadingDataPsa = false;
        this.access_token = '';
        this.$store.commit('payLender/SET_PSA_ACCESS_TOKEN', '');
        this.smsPsaValid = false;
        this.smsRegisterPsaValid = false;
      }
    },
    validateForm() {
      const errors = {};

      const fields = {
        'fio-input': this.userData.fio,
        'inn-input': this.userData.inn,
        'citizenship-select': this.userData.citizenship,
        'passport-combined-input': `${this.userData.passportSeries} ${this.userData.passportNumber}`,
        'division-name-input': this.userData.divisionName,
        'division-code-input': this.userData.divisionCode,
        'registration-place-input': this.userData.registrationPlace,
        'metal-type-select': this.metalType,
        'main-features-input': this.mainFeatures,
        'confirming-doc-select': this.confirmingDocument,
        'number-document-input': this.numberDocument,
        'creation-date-input__outer_': this.creationDate,
      };

      Object.keys(fields).forEach(key => {
        if (key === 'passport-combined-input') {
          if (!this.userData.passportSeries || !this.userData.passportNumber ||
            !/^\d{4}$/.test(this.userData.passportSeries) ||
            !/^\d{6}$/.test(this.userData.passportNumber)) {
            errors[key] = 'Введите корректные серию и номер паспорта';
          }
        } else {
          if (!fields[key]) {
            errors[key] = 'Это поле обязательно для заполнения';
          }
        }
      });

      // Валидация каждой позиции в массиве positions
      this.positions.forEach((position, index) => {
        const positionFields = {
          [`scrap-short-name-input-${index}`]: position.scrapShortName,
          [`measurement-unit-select-${index}`]: position.measurementUnit,
          [`gross-weight-input-${index}`]: position.grossWeight,
          [`tare-weight-input-${index}`]: position.tareWeight,
          [`contamination-input-${index}`]: position.contamination,
          [`net-weight-input-${index}`]: position.netWeight,
          [`price-input-${index}`]: position.price,
          [`total-input-${index}`]: position.total,
        };
        Object.keys(positionFields).forEach(key => {
          if (!positionFields[key]) {
            errors[key] = 'Это поле обязательно для заполнения';
          }
        });
      });

      this.fieldErrors = errors;
      if (Object.keys(errors).length > 0) {
        this.scrollToFirstError();
        return false;
      }
      return true;
    },
    scrollToFirstError() {
      const firstErrorKey = Object.keys(this.fieldErrors)[0];
      const element = document.getElementById(firstErrorKey);
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'center'});
        element.classList.add('error-highlight');
      }
    },
    async onSubmitPassportData() {
      if (!this.validateForm()) {
        this.$toast.error('Пожалуйста, заполните все обязательные поля');
        return;
      }
      const personalInfo = {
        last_name: this.userData.last_name,
        first_name: this.userData.first_name,
        patronymic: this.userData.patronymic,
        number: this.userData.passportNumber,
        series: this.userData.passportSeries,
        issuer_code: this.userData.divisionCode,
        issuer_name: this.userData.divisionName,
        full_address: this.userData.registrationPlace,
        inn: this.userData.inn,
        citizenship: this.userData.citizenship
      };

      const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`;
      };

      const psaData = {
        metal_type: this.metalType,
        main_features: this.mainFeatures,
        confirming_document: this.confirmingDocument,
        number_document: this.numberDocument,
        creation_date: this.creationDate ? formatDate(this.creationDate) : null,
        // Данные по каждой позиции
        positions: this.positions.map(position => ({
          scrap_short_name: position.scrapShortName,
          measurement_unit: position.measurementUnit,
          gross_weight: position.grossWeight,
          tare_weight: position.tareWeight,
          contamination: position.contamination,
          net_weight: position.netWeight,
          price: position.price,
          total: position.total
        })),
        number_auto: this.numberAuto,
        model_auto: this.modelAuto
      };

      const payload = {
        token: this.access_token,
        data: {
          personal_info: personalInfo,
          psa_data: psaData
        }
      };

      if (this.userData.citizenship !=='РФ') {
        this.$emit('goNextStep');
        return;
      }
      await this.$store.dispatch('payLender/savePsaData', payload)
        .then(response => {
          if (!response.success) {
            console.error('Ошибка сохранения данных: ', response.message);
          }
        })
        .catch(error => {
          console.error('Ошибка при вызове экшена: ', error);
        });

      this.$emit('goNextStep');
    },
    addPosition() {
      // Добавление новой позиции в массив positions
      this.positions.push({
        scrapShortName: '',
        measurementUnit: '',
        grossWeight: null,
        tareWeight: null,
        contamination: null,
        netWeight: null,
        price: null,
        total: null
      });
    },
    back() {
      this.phoneNumberPsa = '';
      this.smsRegisterPsaValid = true;
      this.passportNumberPsaValid = true;
      this.phoneNumberPsaRegister = true;
      this.phoneNumberPsaValid = true;
      this.smsPsaValid = true;
      switch (this.step) {
        case 'enterNext':
          this.step = 'checkPhone';
          break;
        case 'enterPassportNumber':
          this.step = 'checkPhone';
          break;
        case 'enterSmsCode':
          this.step = 'checkPhone';
          break;
        case 'enterRegisterSmsCode':
          this.step = 'checkPhone';
          break;
        case 'enterPassportData':
          if (this.phoneNumberPsaValid) {
            this.step = 'checkPhone';
          } else {
            this.step = 'enterNext';
          }
          break;
        default:
          this.step = 'checkPhone';
          break;
      }
      this.clearForm()
    },
    onFocusRemoveErrorHighlight(event) {
      if (event.target.classList.contains('error-highlight')) {
        event.target.classList.remove('error-highlight');
      }
    },
    setupFocusHandlers() {
      console.log('setupFocusHandlers');
      const form = this.$el.querySelector('#PSAForm');
      form.addEventListener('focusin', this.onFocusRemoveErrorHighlight);
    },
    deleteFocusHandlers() {
      console.log('deleteFocusHandlers');
      const form = this.$el.querySelector('#PSAForm');
      form.removeEventListener('focusin', this.onFocusRemoveErrorHighlight);
    },
  },
  mounted() {
    // this.$store.commit('payLender/SET_PSA', null);
    if (this.$store.state.payLender.psa_access_token && this.$store.state.payLender.apiData.psa && this.$store.state.payLender.apiData.psa.psa_data && this.$store.state.payLender.apiData.psa.personal_info) {
      this.access_token = this.$store.state.payLender.psa_access_token;

      // restore dta from store
      const psaData = this.$store.state.payLender.apiData.psa.psa_data;
      this.metalType = psaData.metal_type;
      this.mainFeatures = psaData.main_features;
      this.confirmingDocument = psaData.confirming_document;
      this.numberDocument = psaData.number_document;
      this.creationDate = psaData.creation_date.split('.').reverse().join('-');
      this.numberAuto = psaData.number_auto;
      this.modelAuto = psaData.model_auto;
      this.positions = psaData.positions.map(position => ({
        scrapShortName: position.scrap_short_name,
        measurementUnit: position.measurement_unit,
        grossWeight: position.gross_weight,
        tareWeight: position.tare_weight,
        contamination: position.contamination,
        netWeight: position.net_weight,
        price: position.price,
        total: position.total
      }));
      const userData = this.$store.state.payLender.apiData.psa.personal_info;
      this.userData = {
        fio: `${userData.last_name} ${userData.first_name} ${userData.patronymic}`,
        last_name: userData.last_name,
        first_name: userData.first_name,
        patronymic: userData.patronymic,
        inn: userData.inn,
        citizenship: userData.citizenship,
        passportCombined: `${userData.series} ${userData.number}`,
        passportSeries: userData.series,
        passportNumber: userData.number,
        divisionName: userData.issuer_name,
        divisionCode: userData.issuer_code,
        registrationPlace: userData.full_address,
      }

      this.isNewPsa = false;
      this.step = 'enterPassportData';
    }
  },
}
</script>

<style scoped>
.error-highlight {
  border-color: red;
}

.passport-data-form >>> label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.b-form-btn-label-control.form-control >>> .form-control {
  white-space: nowrap;
}
</style>

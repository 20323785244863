// Модуль, отвечающий за работу вкладки LifeBroker и всего с ней связанного.
import { defaultFilterString } from '@/configs/filtersTables/defaultFilters'
import router from '@/router'

function fileToArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(new Uint8Array(reader.result));
    reader.onerror = error => reject(error);
  });
}

export default {
  namespaced: true,
  state: {
    transactions: [],
    urls: {
      // transactions: '/life-api/api/v1/arm/core/applications/'
      transactions: '/api/v1/arm/core/applications/'
    }
  },
  mutations: {
    updateTransactions(state, value){
      state.transactions = value
    }
  },
  actions: {
    prepareFilterByString (context){
      let filters_config = context.rootState.filtersStd.filtersConfigs[context.rootState.filtersStd.activeFilterConfig]
      //добавляем filterByString
      let filterByString = '';
      // Сперва устанавливаем обязательный фильтр на client_id. Он на самом деле не нужен,
      // потому, что по правам, пользователь не может получить не свои транзакции,
      // но на всякий случай установим.
      //filterByString += '&client_id='+context.rootState.account.cid
      //тут мы опрашиваем фильтры и строим логику для них
      if (context.rootState.filtersStd.dateFrom){
        filterByString += '&created>='+context.rootState.filtersStd.dateFrom
      }
      if (context.rootState.filtersStd.dateTo){
        filterByString += '&created<'+context.rootState.filtersStd.dateTo
      }
      if (context.rootState.filtersStd.statusString){
        filterByString += '&status__status_code in '+context.rootState.filtersStd.statusString
      } else {
        let string = defaultFilterString(filters_config, 'status')
        if (router.history.current.path === '/loans') {
            string = defaultFilterString(filters_config, 'status_loans')
        }
        if (string != ''){filterByString += '&status__status_code in '+string}
      }
      if (context.rootState.filtersStd.searchApp){
        let s = context.rootState.filtersStd.searchApp
        let sf = ''
        if (s.split('-').length > 3 ) {sf = '&application_id='} else {sf = '&contract_number='}
        filterByString += sf + s
      }
      if (context.rootState.filtersStd.searchClient){
        filterByString += '&customer_search='+context.rootState.filtersStd.searchClient
      }

      //очищаем первый &
      if (filterByString && filterByString.charAt(0)==='&'){
        filterByString = filterByString.substring(1)
      }
      filterByString = encodeURIComponent(filterByString)
      context.commit('filtersStd/updateFilterByString', filterByString, {root: true})
    },
    refreshTable (context, queryObject) {
      let url = VUE_APP_API_LIFE_API + context.state.urls.transactions

      //делаем ветку логики на запросы по типам.
      if (queryObject && queryObject.type === 'cursor'){
          url += '?cursor='+queryObject.params.cursor
      }
      else{
        //сперва делаем ограничение по limit_to
        let limit = 'limit_to='+ queryObject.params.limit_to
        url += '?' + limit

        // Создаем строку FilterBy
        context.dispatch('prepareFilterByString')

        //Сортировки тут нет, вроде, поэтому пока пропускаем.

        //Добавляем фильтры
        let filterByString = context.rootState.filtersStd.filterByString
        if (filterByString){
          url += '&' + 'filter_by='+filterByString
        }
      }
      this.$axios.get(url)
      .then(response => {
        let cursor = response.data.cursor
        let transactions = response.data.applications
        context.commit('pagerStd/updateCursor', cursor, { root: true })
        context.commit('pagerStd/updateApiResponseReceived', true, { root: true })
        //Если в запросе передаётся currentOffset, используем его, если нет - ставим 0.
        if (typeof queryObject === 'object' && queryObject !== null && queryObject.hasOwnProperty('currentOffset')){
          context.commit('pagerStd/updateCurrentOffset', queryObject.currentOffset, { root: true })
        }
        else{
          context.commit('pagerStd/updateCurrentOffset', 0, { root: true })
        }
        context.commit('updateTransactions', transactions)

      })
      .catch(error => {
        console.log('error in promise', error)
      })
    },
    OpenLoan(context, app_id) {
      return new Promise((resolve, reject) => {
        let url = VUE_APP_ARM_URL + '/api/applications/OpenLoan?applicationId=' + app_id
        this.$axios.post(url, {}, { responseType: 'json' })
          .then(response => {
            if (response && response.data) {
              if (response.data.result) {
                resolve(response.data);
              } else {
                reject(response.data);
              }
            } else {
              reject(new Error("Data is not present in the response"));
            }
          })
          .catch(error => {
            reject(error);
          })
      })
    },
    downloadAppDocsByPdf(context, app_id) {
      return new Promise((resolve, reject) => {
        let url = VUE_APP_ARM_URL + '/api/applications/GetDocumentsInSinglePdf?applicationId=' + app_id
        this.$axios.get(url, { responseType: 'blob' })
          .then(response => {
            let blob = new Blob([response.data], { type: 'application/pdf' })
            let url = window.URL.createObjectURL(blob)
            window.open(url, '_blank')
            resolve()
          })
          .catch(error => {
            console.log('error in promise', error)
            reject(error)
          })
      })
    },
    downloadListScanDocs(context, app_id) {
      return new Promise((resolve, reject) => {
        let url = `${VUE_APP_ARM_URL}/api/applications/GetRequiredDocuments?applicationId=${app_id}&forVerification=true`
        this.$axios.get(url, { responseType: 'json' })
          .then(response => {
            if (response && response.data) {
              resolve(response.data)
            } else {
              reject(new Error("Data is not present in the response"))
            }
          })
          .catch(error => {
            console.log('error in promise', error)
            reject(error)
          })
      })
    },
    uploadScannedDocument(context, { applicationId, file, fileName, documentTypeId }) {
      return new Promise(async (resolve, reject) => {
        try {
          const fileArrayBuffer = await fileToArrayBuffer(file);
          const url = `${VUE_APP_ARM_URL}/api/applications/UploadScannedDocument`;
          const data = {
            applicationId,
            file: Array.from(fileArrayBuffer),
            fileName,
            documentTypeId
          };
          await this.$axios.post(url, data, { headers: { 'Content-Type': 'application/json' }});
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    deleteScannedDocument(context, { StreamId }) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${VUE_APP_ARM_URL}/api/applications/DeleteDocument?streamId=${StreamId}`;
          await this.$axios.post(url, {}, { headers: { 'Content-Type': 'application/json' }});
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    getScannedDocument(context, StreamId) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${VUE_APP_ARM_URL}/api/applications/GetScannedDocument?streamId=${StreamId}&forVerification=true`;
          const response = await this.$axios.get(url, { responseType: 'blob' });
          resolve(response.data);
        } catch (error) {
          let errorMessage = 'Unknown error';
          if (error.response && error.response.data && error.response.data.Message) {
            errorMessage = error.response.data.Message;
          }
          reject(errorMessage);
        }
      });
    },
    async sendToVerification({commit}, applicationId) {
      try {
        const response = await this.$axios.post(`${VUE_APP_ARM_URL}/api/applications/SendToVerification?applicationId=${applicationId}`);
        return response.data;
      } catch (error) {
        // console.error(error);
        throw error;
      }
    }
  }
}

<template>
  <b-container fluid class="main-container mt-3 mb-3">
    <b-row>
      <b-col>
        <NotificationsService/>
        <div class="common-navbar">
          <!--<CommonNavBar />-->
        </div>
        <div class="component-element">
          <component v-bind:is="currentComponent"></component>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>

import Analytics from '@/components/Tabs/Analytics/tableAnalytics.vue'
import Payments from '@/components/Tabs/Payments/tablePayments.vue'
import Bindings from '@/components/Tabs/Bindings/tableBindings.vue'
import PaymentsChargebacks from '@/components/Tabs/Payments/tablePaymentsChargebacks.vue'
import PaymentsAll from '@/components/Tabs/Payments/tablePaymentsAll.vue'
import Payouts from '@/components/Tabs/Payouts/tablePayouts.vue'
import LandingPage from '@/components/LandingPage.vue'
import PayoutsForm from '@/components/Tabs/Payouts/PayoutsForm.vue'
import Receipts from '@/components/Tabs/Receipts/tableReceipts.vue'
import Partners from '@/components/Tabs/Partners/tablePartners.vue'
import Invoices from '@/components/Tabs/Invoices/invoicesWrapper.vue'
import Pos from '@/components/Tabs/POSapplications/tablePos.vue'
import ExportPage from '@/components/Export/tableReportsList'
import SettingsProfile from '@/components/Settings/Profile.vue'
//import SettingsSecurity from '@/components/Settings/Security.vue'
import SettingsRequisites from '@/components/Settings/Requisites.vue'
import SettingsNotifications from '@/components/Settings/NotificationsEmails.vue'
import SettingsAddBankAccount from '@/components/Settings/AddBank.vue'
import Rewards from '@/pages/rewards/wrapper.vue'

import NotificationsService from '@/components/NotificationsService/notificationsService.vue'


import ProjectEdit from '@/components/ProjectMerchant/projectEdit.vue'
import ProjectAdd from '@/components/ProjectMerchant/projectAdd.vue'
import Search from '@/components/Search/tableSearch.vue'
import LifeBroker from '@/components/TabsLife/Sales/tableBroker.vue'
import LifeBroker_loans from '@/components/TabsLife/Issued/tableBroker.vue'

/* Пример для дальнейшей переработки и рефакторинга.
import NocodeModulesTable from '@/components/Payout/Modules'
import NocodePayMerchant from '@/components/Payout/PayMerchant'
import NocodePayoutMerchant from '@/components/Payout/PayoutMerchant'
import NocodeModulesTransactions from '@/components/Payout/Transactions'
import NocodeModulesRegistries from '@/components/Payout/Registries'
import NocodeModulesTransactionsExtend from '@/components/Payout/Extend'
*/
export default {
  components: {
    Analytics,
    Bindings,
    Payments,
    PaymentsChargebacks,
    PaymentsAll,
    Payouts,
    LandingPage,
    PayoutsForm,
    Receipts,
    Pos,
    ExportPage,
    SettingsProfile,
    //SettingsSecurity,
    SettingsRequisites,
    SettingsNotifications,
    SettingsAddBankAccount,
    Rewards,
    ProjectEdit,
    ProjectAdd,
    Search,
    LifeBroker,
    LifeBroker_loans,
    NotificationsService,
    Partners,
    Invoices
  },
  data() {
    return {}
  },
  computed: {
    currentComponent() {
      let name = 'Analytics'
      if (Object.prototype.hasOwnProperty.call(this.$route, 'name')) {
        name = this.$route.name
      }
      if (!this.isOpenedTabs(name)) {
        name = `LandingPage`
      }
      return name
    },
    showTableName() {
      if (this.$route.params.arg1 === 'analytics') {
        if (this.$route.params.arg2 === 'pending' || this.$route.params.arg2 === 'finalized') {
          return 'loans'
        }
        if (this.$route.params.arg2 === 'payments' || this.$route.params.arg2 === 'returns' || this.$route.params.arg2 === 'all') {
          return 'transactions'
        }
      }
      if (this.$route.params.arg1 === 'rewards' && this.$route.params.arg2 === 'acts') {
        return 'rewardsActs'
      }
      if (this.$route.params.arg1 === 'rewards' && this.$route.params.arg2 === 'act') {
        return 'singleAct'
      }
      if (this.$route.params.arg1 === 'rewards' && this.$route.params.arg2 === 'recipients') {
        return 'rewardsRecipients'
      }
      if (this.$route.params.arg1 === 'nocode' && this.$route.params.arg2 === 'modules') {
        this.defineActiveRowColumnAndModule()
        return 'modules'
      }
      if (this.$route.params.arg1 === 'nocode') {
        if (this.$route.params.arg2 === 'modulestransactions') {
          this.defineActiveRowColumnAndModule()
          return 'modulestransactions'
        }
        if (this.$route.params.arg3 === 'payout') {
          this.defineActiveRowColumnAndModule()
          return 'payout'
        }
        if (this.$route.params.arg3 === 'pay') {
          this.defineActiveRowColumnAndModule()
          return 'pay'
        }
      }
      if (this.$route.params.arg1 === 'receipts') {
        this.defineActiveRowColumnAndModule()
        return 'receipts'
      }
      return 'transactions'
    }
  },
  created() {
    // Подгружаем данные о пользователе для враппера и навигации
    if (this.$store.state.account.cid !== undefined) {
      this.$store.dispatch('loadV1ClientObject', this.$store.state.account.cid)
    } else {
      console.log('undefined cid')
    }

  },
  mounted() {

  },
  methods: {
    isOpenedTabs(tab = 'Analytics') {
      const services = this.$store.state.account.clientData.services || []
      if (tab === 'Payouts') {
        return services.some(service => service.codename === 'payout_services' && service.options && service.options.some(option => option.is_selected))
      }
      if (tab === 'Payments') {
        return services.some(service => service.codename === 'payin_services' && service.options && service.options.some(option => option.is_selected))
      }
      if (tab === 'LifeBroker') {
        return services.some(service => service.codename === 'credit_services' && service.options && service.options.some(option => option.is_selected))
      }
      return true
    },
  }
}
</script>

<template>
  <div v-html="pageContent" class="page-container unique-container"></div>
</template>

<script>
import axios from 'axios';
import postcss from 'postcss';
import prefixSelector from 'postcss-prefix-selector';

export default {
  name: 'FetchedPageContent',
  data() {
    return {
      pageContent: '',
      cssLoaded: false,
      jsLoaded: false
    }
  },
  watch: {
    $route() {
      this.fetchPageContent();
    }
  },
  mounted() {
    this.fetchPageContent();
  },
  methods: {
    async fetchPageContent() {
      try {
        const name = this.$route.name
        const pageId = {
          'Payouts': VUE_APP_API_TILDA_PAGE_PAYOUTS,
          'Payments': VUE_APP_API_TILDA_PAGE_PAYIN,
          'LifeBroker': VUE_APP_API_TILDA_PAGE_CREDIT,
        }[name] || null;
        let url = VUE_APP_API_TILDA.replaceAll('METHODNAME', 'getpage') + '&pageid=' + pageId;
        const response = await axios.get(url);
        this.pageContent = response.data.result.html;
        this.loadCSS(response.data.result.css);
        this.loadJS(response.data.result.js);
      } catch (error) {
        console.error('Failed to fetch page content:', error);
        this.pageContent = 'Failed to load content.';
      }
    },
    async loadCSS(cssFiles) {
      for (const cssFile of cssFiles) {
        try {
          const response = await axios.get(cssFile);
          const cssContent = response.data;

          // Префиксируем селекторы
          const prefixedCSS = await postcss([
            prefixSelector({
              prefix: '.unique-container',
              transform: (prefix, selector, prefixedSelector) => {
                // Не префиксировать @-правила и ключевые селекторы
                if (selector.startsWith('@') || selector.startsWith('html') || selector.startsWith('body')) {
                  return selector;
                }
                return prefixedSelector;
              },
            }),
          ]).process(cssContent, { from: undefined });

          // Вставляем префиксированный CSS в документ
          const style = document.createElement('style');
          style.type = 'text/css';
          style.innerHTML = prefixedCSS.css;
          document.head.appendChild(style);
        } catch (error) {
          console.error(`Failed to load CSS file ${cssFile}:`, error);
        }
      }
      this.cssLoaded = true;
    },
    loadJS(jsFiles) {
      jsFiles.forEach(jsFile => {
        const script = document.createElement('script');
        script.src = jsFile;
        script.type = 'text/javascript';
        document.body.appendChild(script);
      });
      this.jsLoaded = true;
    }
  }
}
</script>

<style>
.page-container {
  width: calc(100% - 30px);
  min-height: calc(100vh - 300px);
  border: none;
}
</style>

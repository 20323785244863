<template>
  <div class="w-100 min-vh-300">
    <div v-if="options && options.length > 0" class="container-form">
      <div class="form-payout">
        <h4>Выберите проект</h4>
        <div class="d-flex justify-content-between align-items-center">
          <Multiselect
            v-if="Array.isArray(options)"
            v-model="selectedOption"
            :options="Array.isArray(options) ? options : []"
            :disabled="isMidDisabled"
            :class="{'selected-mid': showForm}"
            track-by="label"
            label="label"
            deselect-label="Удалить"
            select-label="Добавить"
            selected-label="Опция выбрана"
            placeholder="ID Проекта (MID)"
          ></Multiselect>
        </div>
        <div class="d-flex justify-content-between">
          <router-link v-if="selectedOption && !iframeUrl" :to="`/project/${selectedOption.value}?tab=4`"
                       class="ps-0 pe-4 py-2">Настройка формы
          </router-link>
          <a v-else-if="iframeUrl" class="ps-4 pe-0 py-2" @click.prevent="go_back" href="#">Вернуться</a>
          <div v-else></div>
          <a class="ps-4 pe-0 py-2"
             href="https://mndr.in/payout_form"
             target="_blank">Инструкция</a>
        </div>
      </div>

    </div>
    <hr v-if="showForm"/>
    <div v-if="(balance) ||
    (daily_limit && daily_limit.amountLeft) ||
    (monthly_limit && monthly_limit.amountLeft) ||
    transaction_limit" class="container-form">
      <div class="form-payout">
        <div class="limits-info">
          <div v-if="!isLoadingLimits">
            <p v-if="!isLoadingBalance && balance_total" class="mobile-layer">
              <strong>Баланс счета:</strong> {{ properMRub(balance_total) }}
            </p>
            <p v-if="monthly_limit && monthly_limit.amountLeft">
              <strong>Ваш лимит:</strong> {{ properMRub(monthly_limit.amountLeft) }}/месяц
              <span v-if="daily_limit.amountLeft">
              , {{ properMRub(daily_limit.amountLeft) }}/день
              </span>
              <span v-if="transaction_limit">
              , {{ properMRub(transaction_limit) }}/операция
              </span>
            </p>
            <p v-else-if="daily_limit.amountLeft">
              <strong>Ваш лимит:</strong> {{ properMRub(daily_limit.amountLeft) }}/день
              <span v-if="transaction_limit">
              , {{ properMRub(transaction_limit) }}/операция
              </span>
            </p>
            <p v-else-if="transaction_limit">
              <strong>Ваш лимит:</strong> {{ properMRub(transaction_limit) }}/операция
            </p>
          </div>
          <div v-else class="text-center w-100">
            <b-spinner class=""></b-spinner>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showForm2" class="container-form">
      <div class="form-payout">
        <div class="limits-info">
          <p v-if="amount"><strong>Сумма:</strong> {{ properMRub(parseFloat(amount)) || '' }}</p>
          <p v-if="order_id"><strong>Номер заказа:</strong> {{ order_id }}</p>
          <p v-if="phone" class=""><strong>Номер телефона:</strong> {{ phone }}</p>
          <p v-if="email"><strong>Email:</strong> {{ email }}</p>
          <template v-if="optionalFields.length > 0" v-for="(field, index) in optionalFields">
            <p><strong>{{ field.key }}:</strong> {{ field.value }}</p>
          </template>
        </div>
      </div>
    </div>

    <!-- Самый первый ввод по настройкам с ПСА-->
    <div v-if="getFirstPage && docVisible" class="container-form">
      <div v-if="docVisible === 'psa'">
        <StepsPSA @goBack="go_back" @goNextStep="afterPSA"></StepsPSA>
      </div>
    </div>

    <!-- Самый первый ввод по настройкам без ПСА-->
    <div v-if="getFirstPage && !docVisible" class="container-form">
      <div class="form-payout">
        <b-form-group class="mb-3" v-if="!orderHide">
          <b-form-input
            :hidden="orderHide"
            v-model="order_id"
            :placeholder="orderPlaceholder"
            :readonly="orderReadonly"
            @input="orderValid = /\\|\t/.test(order_id)"
          ></b-form-input>
          <span v-if="orderValid" class="text-danger">Введены недопустимые символы: \ или Tab</span>
        </b-form-group>

        <b-form-group class="mb-3" v-if="!phoneHide">
          <b-form-input
            v-model="phone"
            placeholder="Номер телефона"
          ></b-form-input>
        </b-form-group>

        <b-form-group class="mb-3" v-if="!emailHide">
          <b-form-input
            type="email"
            v-model="email"
            placeholder="Действительный email"
          ></b-form-input>
        </b-form-group>

        <b-form-group class="mb-3">
          <!-- :readonly="disableAmount"-->
          <InputFormatted
            v-model="amount"
            :readonly="readOnlyAmount"
            suffix=" ₽"
            placeholder="Сумма выплаты"
          ></InputFormatted>
        </b-form-group>

        <div v-for="(field, index) in optionalFields" :key="index" class="form-group">
          <b-form-input
            :type="field.type || 'text'"
            class="form-control"
            :name="`optional_info[${field.key}]`"
            :placeholder="field.key"
            v-model="field.value">
          </b-form-input>
        </div>

        <b-button @click="onPreSubmit" variant="primary" class="w-100">
          <span v-if="!isLoadingData">Начать выплату</span>
          <b-spinner v-else></b-spinner>
        </b-button>
      </div>
    </div>

    <!-- Второй шаг-->
    <div v-show="!showPayoutStatus && showForm2 && showForm" class="container-form">

      <!-- Выбор способа выплаты-->
      <div v-if="sbpVisible" class="form-payout mb-3">
        <div class="card-card btn m-1" :class="{'selected-card': payoutsMethod === 'card'}"
             @click="payoutsMethodSelect('card')">
          <img src="@/assets/images/credit-card-outline.svg" alt="card">
        </div>
        <div class="card-sbp btn m-1" :class="{'selected-card': payoutsMethod === 'sbp'}"
             @click="payoutsMethodSelect('sbp')">
          <img src="@/assets/images/sbp-outline.svg" alt="sbp">
        </div>
      </div>

      <!-- Выплата-->
      <div class="form-payout">

        <div v-if="payoutsMethod === 'card'">

          <!-- Оплата на карту без проверки-->
          <div v-if="cardInputMethod === 'direct'">
            <b-form-input v-mask="'#### #### #### #### ###'" v-model="cardNumber" placeholder="0000 0000 0000 0000"
                          class="custom-input"></b-form-input>
            <button
              href="#"
              @click.prevent="onSubmit2"
              class="btn btn-primary w-100 mt-2"
              :disabled="isLoadingData"
            >
              <span v-if="!isLoadingData">Выплатить на карту</span>
              <span v-else>
                <b-spinner style="height: 16px; width: 16px;"></b-spinner>
                Отправка запроса...
              </span>

            </button>
          </div>

          <!-- Оплата на карту с проверкой-->
          <div id="custom-pay-container">
            <form id="form-hosted-pay" v-show="cardInputMethod === 'mandarin' || cardInputMethod === 'mandarin3ds'">
              <div class="mandarinpay-field-card-number hosted-field form-control mr-2"
                   style="flex-grow: 1; width: calc(100% - 120px);"></div>
              <div class="mandarinpay-field-card-expiration hosted-field form-control"
                   style="flex: 0 0 80px; width: 80px;"></div>
              <br/>
              <a href="#" onclick="return mandarinpay.hosted.process(this);"
                 class="form-hosted-pay-button btn btn-primary w-100 mt-2 d-none">Выплатить</a>
            </form>
          </div>
          <!-- Click button to click tag a in form with id form-hosted-pay -->
          <button
            v-show="cardInputMethod === 'mandarin' || cardInputMethod === 'mandarin3ds'"
            href="#"
            @click="clickButtonFormHostedPay"
            class="btn btn-primary w-100 mt-2"
            :disabled="isLoadingData"
          >
            <span v-if="!isLoadingData">Выплатить</span>
            <span v-else>
                <b-spinner style="height: 16px; width: 16px;"></b-spinner>
                Отправка запроса...
            </span>
          </button>
        </div>

        <!-- Выплата через СБП -->
        <div v-if="payoutsMethod === 'sbp'">
          <div>
            <multiselect
              v-if="Array.isArray(banks) && banks.length > 0"
              v-model="selectedBank"
              :options="banks"
              track-by="label" label="label"
              :loading="loadingBanks"
              placeholder="Выберите банк">
            </multiselect>
            <vue-tel-input :inputOptions="phoneGlobalOptions" class="form-control mt-2"
                           v-model="phoneNumberSbp"></vue-tel-input>

            <div v-if="transaction && transaction.success" class="">
              <p v-if="transactionFio" class="mt-3 mb-2">ФИО: {{ transactionFio }}</p>
              <p v-if="transactionError" class="text-danger mt-3 mb-2">{{ transactionError }}</p>
              <div class="d-flex justify-content-between">
                <div class="pr-2 w-50">
                  <button @click="stopPollingAndReset" class="btn btn-outline-primary w-100 mt-2">Назад</button>
                </div>
                <div class="pl-2 w-50">
                  <button @click="confirmPayment" :disabled="!canConfirm || isLoadingData" class="btn btn-primary w-100 mt-2">
                    <span v-if="isStatusInterval">
                      <b-spinner style="height: 16px; width: 16px;"></b-spinner>
                      <span class="pl-2">запрос ФИО...</span>
                    </span>
                    <span v-else-if="!isLoadingData">Выплатить</span>
                    <span v-else>
                      <b-spinner style="height: 16px; width: 16px;"></b-spinner>
                      Отправка запроса...
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <button v-else href="#" @click="onSubmit3" class="btn btn-primary w-100 mt-2" :disabled="!selectedBank">
              <span v-if="!isLoadingData">Далее</span>
              <span v-else>
                <b-spinner style="height: 16px; width: 16px;"></b-spinner>
                Отправка запроса...
              </span>
            </button>
          </div>
        </div>

      </div>
    </div>

    <!-- Показывается статус операции-->
    <div v-if="showPayoutStatus" class="container-form">
      <div class="form-payout mb-4"
           :style="{'opacity': isLoadingDocuments ? '0.5' : '1', 'pointer-events': isLoadingDocuments ? 'none' : 'auto'}"
           v-if="transaction && (transaction.state === 'success' || transaction.state === 'payout-only') && psa && psa.psa_data">
        <h5>Данные ПСА</h5>
        <div>
          <a href="#" @click.prevent="downloadRGDoc()">
            <span>Акт №: {{ psa.psa_data.number_document }} от {{ psa.psa_data.creation_date }}</span>
            <i style="color: #007bff; font-size: 14px;" class="fa fa-download ml-2"></i>
          </a>
        </div>
        <div>
          <a href="#" @click.prevent="downloadRGDoc('agreement_')">
            <span>Соглашение о персональных данных</span>
            <i style="color: #007bff; font-size: 14px;" class="fa fa-download ml-2"></i>
          </a>
        </div>
<!--        <div>-->
<!--          <a href="#" @click.prevent="downloadRGDoc()">-->
<!--            <span>Выписка о проверке паспортных данных</span>-->
<!--            <i style="color: #007bff; font-size: 14px;" class="fa fa-download ml-2"></i>-->
<!--          </a>-->
<!--        </div>-->
        <div v-if="isLoadingDocuments" class="text-center w-100 mt-n5">
          <b-spinner class=""></b-spinner>
        </div>
      </div>
      <div class="form-payout text-center" style="height: 276px;">

        <!-- Сценарий 1: Идёт обработка операции -->
        <template
          v-if="transaction && (transaction.state === 'processing' || transaction.state === 'bindings' || transaction.state === 'new')">
          <b-spinner class="mt-4" style="width: 60px; height: 60px;"></b-spinner>
          <p class="mt-2 mb-2 bold">Идёт обработка операции</p>
          <p>Пожалуйста, ожидайте окончания</p>
        </template>

        <!-- Сценарий 2: Успешно -->
        <template v-else-if="transaction && (transaction.state === 'success' || transaction.state === 'payout-only')">
          <img class="mt-4" src="@/assets/images/transaction_success.svg" alt="Успешно">
          <p class="mt-2 mb-2 bold">Успешно!</p>
          <p>Денежные средства отправлены покупателю</p>
        </template>

        <!-- Сценарий 3: Ошибка -->
        <template v-else-if="transaction">
          <img class="mt-4" src="@/assets/images/transaction_failed.svg" alt="Ошибка">
          <p class="mt-2 mb-2 bold">Ошибка!</p>
          <p>{{ transaction.error_desc }}</p>
        </template>

        <!-- Ссылка на новую выплату -->
        <a href="#" @click.prevent="go_back_is_new" class="btn btn-primary w-100 mt-2">
          Сделать новую выплату
        </a>

      </div>
    </div>

    <div v-if="!options || options.length < 1" class="container-form form-payout">
      У вас нет настроенных форм для выплат. Выберите проект из списка ниже для настройки.
      <div class="mt-5">
        <!--        <img alt="Example" src="@/assets/images/payouts_example.png" class="example-image">-->
        <b-form-group label="Проект:">
          <b-form-select v-model="selectedMerchant" :options="getAllMerchantsOptions" @change="gotoProjectSetting"
                         aria-placeholder="Выберите проект"></b-form-select>
        </b-form-group>
      </div>
    </div>

  </div>
</template>

<script>
function validateCardNumber(number) {
  let regex = new RegExp("^[0-9]{13,19}$");
  if (!regex.test(number))
    return false;

  return luhnCheck(number);
}

function validatePhoneNumber(phoneNumber) {
  const regex = /^\+\d{11}$/;
  return regex.test(phoneNumber);
}

function luhnCheck(val) {
  let sum = 0;
  for (let i = 0; i < val.length; i++) {
    let intVal = parseInt(val.substr(i, 1));
    if ((val.length - i) % 2 === 0) {
      intVal *= 2;
      if (intVal > 9) {
        intVal -= 9;
      }
    }
    sum += intVal;
  }
  return (sum % 10) === 0;
}

import Multiselect from "vue-multiselect";
import {VueMaskDirective} from 'v-mask';
import {VueTelInput} from 'vue-tel-input';
import InputFormatted from "@/components/Elements/input-formatted/InputFormatted";
import StepsPSA from "./StepsPSA";

export default {
  directives: {
    'mask': VueMaskDirective,
  },
  components: {VueTelInput, Multiselect, InputFormatted, StepsPSA},
  data() {
    return {
      isLoadingDocuments: false,
      isStatusInterval: null,
      canConfirm: false,
      transactionFio: null,
      transactionError: null,
      scriptLoaded: false,
      selectedOption: null,
      selectedMerchant: null,
      order_id: '',
      phone: '',
      email: '',
      amount: '',
      readOnlyAmount: false,
      amountF: '',
      modules: {},
      monthly_limit: null,
      daily_limit: null,
      transaction_limit: null,
      balance: null,
      orderHide: false,
      phoneHide: false,
      emailHide: false,
      cardVisible: true,
      orderReadonly: false,
      orderPlaceholder: 'Введите номер заказа (order_id)',
      orderValid: false,
      optionalFields: [],
      iframeUrl: false,
      cardInputMethod: null,
      cardNumber: '',
      options: [],
      originalFormHtml: `
      <form id="form-hosted-pay" v-show="cardInputMethod === 'mandarin' || cardInputMethod === 'mandarin3ds'">
            <div class="mandarinpay-field-card-number hosted-field form-control mr-2" style="flex-grow: 1; width: calc(100% - 120px);"></div>
            <div class="mandarinpay-field-card-expiration hosted-field form-control" style="flex: 0 0 80px; width: 80px;"></div>
            <br/>
            <a href="#" onclick="return mandarinpay.hosted.process(this);" class="form-hosted-pay-button btn btn-primary w-100 mt-2 d-none">Выплатить</a>
          </form>
    `,
      transactionUpdateInterval: null,
      payoutsMethod: 'card',
      selectedBank: null,
      phoneNumberSbp: '',
      phoneNumberSbpCorrect: '',
      surnameSbp: '',
      nameSbp: '',
      patronymicSbp: '',
      phoneGlobalOptions: {
        autofocus: true,
        showDialCode: true,
        placeholder: '+7 999 999 99 99'
      },
      newCardTransactionExist: null,
      sbpVisible: false,
      docVisible: false,
    };
  },
  computed: {
    disableAmount() {
      return this.psa && this.psa.psa_data && this.psa.psa_data.positions && this.psa.psa_data.positions.length > 0;
    },
    // compare arrays merchants and merchants_name and return array with objects
    getAllMerchantsOptions() {
      let options = [{text: 'Выберите проект', value: null}];
      for (let i = 0; i < this.$store.state.account.merchants.length; i++) {
        let option = {
          value: this.$store.state.account.merchants[i],
          text: `${this.$store.state.account.merchants[i]} - ${this.$store.state.account.merchants_name[i]}`
        }
        options.push(option)
      }
      return options
    },
    getFirstPage() {
      // !showPayoutStatus && !showForm2 && showForm
      return !this.$route.params.step && !this.iframeUrl && this.selectedOption;
    },
    banks() {
      if (this.$store.state.payLender.loadingBanks) {
        return [{value: null, value_bic: null, label: 'Загружаются...'}]
      } else {
        return this.$store.state.payLender.banks;
      }
    },
    loadingBanks() {
      return this.$store.state.payLender.loadingBanks;
    },
    isLoadingLimits() {
      return this.$store.state.projectLimits.busy;
    },
    isLoadingBalance() {
      return this.$store.state.balance.balance_loaded;
    },
    balance_total() {
      return this.$store.state.balance.balance_total;
    },
    isLoadingData() {
      return this.$store.state.payLender.loadingData;
    },
    showPayoutStatus() {
      return this.$route.params.step;
    },
    transaction() {
      return this.$store.state.payLender.apiData.transaction;
    },
    psa() {
      return this.$store.state.payLender.apiData.psa;
    },
    showForm() {
      return this.selectedOption;
    },
    showForm2() {
      return this.iframeUrl;
    },
    isMidDisabled() {
      return ((this.options && this.options.length === 1) || this.iframeUrl || Boolean(this.showPayoutStatus))
    },
  },
  methods: {
    afterPSA(){
      this.docVisible = false
      this.amount = Number(this.psa.psa_data.positions.reduce((acc, item) => Number(acc) + Number(item.total), 0)).toFixed(2)
      this.readOnlyAmount = true
    },
    async downloadRGDoc(agreement='') {
      this.isLoadingDocuments = true
      try {
        const RequestData = {parameters: {psa_data: {...this.psa.psa_data}}}
        RequestData.parameters.psa_data['positions'] = RequestData.parameters.psa_data.positions.map(item => {
          return {
            ...item, metal_type: this.psa.psa_data.metal_type
          }
        })
        RequestData.parameters.psa_data['legal_name'] = this.$store.state.account.clientData.legal_info.legal_name
        RequestData.parameters.personal_info = {...this.psa.personal_info}

        let response = await this.axios.post(`${VUE_APP_API}/report-generator-nt/v1/report/free-format?report_format=pdf&template=%2Freports%2Ftransactions%2F${agreement}psa`, RequestData, {
          responseType: 'blob'
        });

        if (response.status !== 200) {
          this.$toast.error(`Ошибка: ${response.status} ${response.statusText}`)
          return
        }

        let blob = new Blob([response.data], {type: 'application/pdf'});
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.target = '_blank';
        link.click();

      } catch (error) {
        let error_text = 'Произошла неизвестная ошибка при генерации отчета';
        if (error.response && error.response.status) {
          if (error.response.status === 500) {
            error_text = 'Для получения справки обратитесь в Службу поддержки'
          }
          if (error.response.status === 403) {
            error_text = 'У вас не хватает прав для доступа к данному функционалу. Обратитесь к вашему руковуодителю для корректной настройки прав'
          }
        }
        this.$toast.error(error_text);
        this.isLoadingDocuments = false
      }
      this.isLoadingDocuments = false
    },
    clickButtonFormHostedPay() {
      this.setIsLoadingData(true);
      document.getElementsByClassName('form-hosted-pay-button')[0].click();
    },
    validateAmount(amount) {
      const regex = /^\d+(\.\d{1,2})?$/;
      return regex.test(amount);
    },
    gotoProjectSetting(val) {
      if (val) {
        console.log('goto route in gotoProjectSetting')
        this.$router.push(`/project/${val}?tab=4`);
      }
    },
    setIsLoadingData(value) {
      this.$store.commit('payLender/SET_LOADING_DATA', value)
    },
    async get_options() {
      console.log('Calculate options');
      let options = [];
      let name = '';
      let mid = null;
      const results = this.$store.state.payLender.apiData.results;

      if (Array.isArray(results) && results.length > 0) {
        mid = this.$route.params.mid;
        console.log('merchants_name = ', this.$store.state.account.merchants_name)
        results.forEach(site => {
          // find index in state.account.merchants? and get name in state.account.merchants_name
          let index = this.$store.state.account.merchants.indexOf(Number(site.merchant_id))
          if (index !== -1) {
            console.log('index = ', index, ' & name = ', this.$store.state.account.merchants_name[index])
            name = this.$store.state.account.merchants_name[index];
          }

          let option = {
            value: site.merchant_id,
            label: (name) ? name + ' - Mid ' + site.merchant_id : site.merchant_id,
          };

          let enable = true;
          if (site.mandatory_info.security && site.mandatory_info.security.enable) {
            if (site.mandatory_info.security.list) {
              if (!site.mandatory_info.security.list.includes(this.$store.state.account.mandarin.email)) {
                enable = false;
              }
            }
          }

          if (mid && Number(site.merchant_id) === Number(mid) && enable) {
            this.selectedOption = option;
          }
          if (option.value > 0 && site.is_active && enable) {
            options.push(option);
            this.modules[option.value] = site;
          }
        });

        options.sort(this.compareByValue);
      } else {
        console.error('Expected an array but got:', results);
      }

      console.log('Final options:', options); // Логируем итоговые options
      this.options = Array.isArray(options) ? options : [];
      if (options.length === 1) {
        this.selectedOption = options[0];
      }
    },
    go_back() {
      this.cardNumber = '';
      this.selectedBank = null;
      this.phoneNumberSbp = '';
      this.phoneNumberSbpCorrect = '';
      this.transactionFio = null;
      this.transactionError = null;
      this.amount = '';
      this.iframeUrl = false;
      this.payoutsMethod = null;
      const mid = this.$route.params.mid;
      console.log('goto route in get_back')
      this.$router.push({name: 'PayoutsForm', params: {mid: mid}});
      this.renderForm(mid);
      this.$emit('updateHistory');
      this.$store.commit('payLender/set_transaction', null);
    },
    go_back_is_new() {
      this.$store.commit('payLender/SET_PSA', {});
      this.go_back()
    },
    async initializeMandarinForm(operationId = null) {
      console.log('Start initializeMandarinForm', operationId);
      this.$nextTick(() => {
        const oldFormElement = document.getElementById('form-hosted-pay');
        if (oldFormElement) {
          oldFormElement.remove();  // Удаляем старую форму
        }
        const newFormElement = document.createElement('form');  // Создаем новый элемент формы
        newFormElement.id = 'form-hosted-pay';
        newFormElement.innerHTML = this.originalFormHtml;
        document.getElementById('custom-pay-container').appendChild(newFormElement);
        const formElement = document.getElementById('form-hosted-pay');
        if ((this.cardInputMethod === 'mandarin' || this.cardInputMethod === 'mandarin3ds') && formElement && this.payoutsMethod === 'card') {
          mandarinpay.hosted.setup("#form-hosted-pay", {
            operationId: operationId,
            onsuccess: (data) => {
              this.$toast.success("Запрос на выплату принят. Ожидайте...");
              this.$emit('updateHistory');
              console.log('goto route in success post custompay')
              this.$router.push({
                name: 'PayoutsForm',
                params: {mid: this.$route.params.mid, tid: this.$route.params.tid, step: '2'}
              });
              this.setIsLoadingData(false);
            },
            onerror: (data) => {
              // this.$toast.error("Error code: " + data.errorCode + " text: " + data.error);
              this.setIsLoadingData(false);
              this.$toast.error({
                component: {
                  template: "<div>Код: " + data.errorCode + " (<a target='_blank' href='https://mandarin.io'>Справочник ошибок</a>)</br>Ошибка: " + data.error + "</div>"
                },
                timeout: false,
                closeOnClick: false,
                pauseOnFocusLoss: false,
                draggable: false,
                pauseOnHover: true
              });
            },
            oncancel: (data) => {
              console.log(data);
              this.setIsLoadingData(false);
            },
            onvalidationerror: () => {
              this.$toast.error("Validation error");
              this.setIsLoadingData(false);
            },
            onformstatechange: (state) => {
              console.log(state);
            },
            onsubmit: () => {
              // this.setIsLoadingData(true);
            },
            fields: {
              "card-number": {
                settings: {
                  placeholder: 'Номер карты',
                  styles: {
                    "font-size": "16px",
                    "font-family": "Helvetica"
                  },
                }
              },
              "card-cvv": {
                placeholder: 'ММ/ГГ',
                settings: {
                  styles: {
                    "font-size": "16px",
                    "font-family": "Helvetica"
                  },
                },
              },
            }
          });
        } else {
          console.log('Not tag for CustomPay');
        }
      });
    },
    compareByValue(a, b) {
      return Number(a.value) - Number(b.value);
    },
    json_data(action, n_card = null, sbp = null) {
      let json_data = {
        "action": action,
        "order_id": this.order_id,
        "amount": this.amount,
        "email": this.email,
        "phone": this.phone,
      };
      if (this.optionalFields && this.optionalFields.length > 0) {
        this.optionalFields.forEach(field => {
          json_data[`optional_info[${field.key}]`] = field.value;
        });
      }
      if (n_card) {
        json_data["n_card"] = n_card;
      }
      if (sbp) {
        json_data["sbp"] = sbp;
      }
      return json_data;
    },
    async payoutsMethodSelect(method) {
      this.payoutsMethod = method;
      if (this.newCardTransactionExist && method === 'card') {
        let mid = this.selectedOption.value;
        this.$router.push({name: 'PayoutsForm', params: {mid: mid, tid: this.newCardTransactionExist}});
        await this.initializeMandarinForm(this.newCardTransactionExist)
      } else {
        await this.onSubmit();
      }
    },
    async onPreSubmit() {
      this.payoutsMethod = 'card';

      // Валидация order_id
      if (!this.orderHide && (/\t/.test(this.order_id) || /\\/.test(this.order_id)) || this.order_id.trim() === '') {
        this.$toast.error('ID заказа не должен быть пустым и содержать \\ или Tab.');
        return;
      }

      // Валидация телефона
      if (!this.phoneHide && !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(this.phone)) {
        this.$toast.error('Номер телефона указан некорректно.');
        return;
      }

      // Валидация email
      if (!this.emailHide && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/.test(this.email)) {
        this.$toast.error('Email указан некорректно.');
        return;
      }

      // Валидация суммы
      let parsedAmount = parseFloat(`${this.amount}`.replaceAll(',', '.'));
      console.log(parsedAmount);
      if (!isNaN(parsedAmount)) {
        this.amount = `${parsedAmount.toFixed(2)}`;
      } else {
        this.amount = '';
      }
      if (!this.validateAmount(this.amount)) {
        this.$toast.error('Сумма указана некорректно. Исправьте пожалуйста и повторите попытку.');
        return;
      }

      // Валидация дополнительных полей
      for (const field of this.optionalFields) {
        if (!field.value.trim()) {
          this.$toast.error(`Поле "${field.key}" не должно быть пустым.`);
          return;
        }
      }

      // Если все проверки пройдены успешно, вызываем onSubmit
      await this.onSubmit();
    },
    async onSubmit() {
      this.iframeUrl = true;

      if (this.payoutsMethod === 'card' && this.cardInputMethod !== 'direct') {
        let mid = this.selectedOption.value;
        await this.$store.dispatch('payLender/createBinding', {data: this.json_data('payout'), mid: mid});
        let transaction = this.$store.state.payLender.apiData.transaction;
        if (transaction && transaction.success) {
          console.log('goto route in success onSubmit')
          this.newCardTransactionExist = transaction.id;
          this.$emit('updateHistory');
          this.$router.push({name: 'PayoutsForm', params: {mid: mid, tid: transaction.id}});
        } else {
          this.iframeUrl = false;
          if (transaction && transaction.error) {
            this.$toast.error("Ошибка выплаты: " + transaction.error);
          } else {
            this.$toast.error("Ошибка выплаты. Пожалуйста повторите попытку или обратитесь в службу поддержки.");
          }
        }
      }
    },
    async onSubmit2() {
      let cardNumber = this.cardNumber.replace(/\s/g, '')
      if (validateCardNumber(cardNumber)) {
        let mid = this.selectedOption.value;
        await this.$store.dispatch('payLender/createTransaction', {
          data: this.json_data('payout', cardNumber),
          mid: mid
        });
        let transaction = this.$store.state.payLender.apiData.transaction;
        if (transaction && transaction.success) {
          this.cardNumber = '';
          this.amount = '';
          this.$toast.success("Запрос на выплату принят. Ожидайте...");
          this.$emit('updateHistory');
          console.log('goto route in success onSubmit2')
          this.$router.push({name: 'PayoutsForm', params: {mid: mid, tid: transaction.id, step: '2'}});
        } else {
          this.$toast.error("Ошибка выплаты: " + transaction.message || 'Неизвестная ошибка');
        }
      } else {
        this.$toast.error('Номер карты указан некорректный. Исправьте пожалуйста и повторите попытку.')
      }
    },
    async onSubmit3() {
      if (!this.selectedBank || (this.selectedBank && !this.selectedBank.value)) {
        this.$toast.error('Выберите банк.');
        return;
      }

      if (this.phoneNumberSbp) this.phoneNumberSbpCorrect = this.phoneNumberSbp.replaceAll(' ', '')
      if (this.phoneNumberSbp && !validatePhoneNumber(this.phoneNumberSbpCorrect)) {
        this.$toast.error('Номер телефона введен некорректно.');
        return;
      }

      let data = {
        bankId: this.selectedBank.value,
        bankBic: this.selectedBank.value_bic,
        phoneNumber: this.phoneNumberSbpCorrect,
      };

      let mid = this.selectedOption.value;

      await this.$store.dispatch('payLender/createTransaction', {data: this.json_data('payout', null, data), mid: mid});
      let transaction = this.$store.state.payLender.apiData.transaction;

      if (transaction && transaction.success) {
        // this.$toast.success("Запрос на выплату принят. Ожидайте...");
        this.startStatusPolling();
        this.$emit('updateHistory');
        // console.log('goto route in success onSubmit3')
        // this.$router.push({name: 'PayoutsForm', params: {mid: mid, tid: transaction.id, step: '2'}});
      } else {
        this.$toast.error("Ошибка выплаты: " + (transaction.error || 'Неизвестная ошибка'));
      }
    },
    async fetchSbpStatus() {
      // this.transactionFio = 'Terminator'
      // this.isStatusInterval = false;
      // this.canConfirm = true
      // return
      try {
        const result = await this.$store.dispatch('payLender/fetchSbpStatus', this.transaction.id);
        console.log('fetchSbpStatus', result);
        this.transactionFio = result.fio;
        if (result.status !== 'pending') {
          clearInterval(this.statusInterval);
          this.isStatusInterval = false;
          this.canConfirm = result.status === 'success';
          if (result.status === 'error') {
            this.transactionError = 'Ошибка при проверке статуса';
            this.$toast.error(this.transactionError);
          } else if (result.status === 'fail') {
            this.$router.push({
              name: 'PayoutsForm',
              params: {mid: this.selectedOption.value, tid: this.transaction.id, step: '2'}
            })
          }
        }
      } catch (error) {
        clearInterval(this.statusInterval);
        this.isStatusInterval = false;
        this.transactionError = error.message;
        this.$toast.error(this.transactionError);
      }
    },

    async confirmPayment() {
      if (!this.canConfirm) return;
      try {
        const result = await this.$store.dispatch('payLender/confirmSbpPayment', this.transaction.id);
        if (result.status === 200) {
          this.$toast.success("Запрос на выплату принят. Ожидайте...");
          this.$emit('updateHistory');
          console.log('goto route in success confirmPayment')
          this.$router.push({
            name: 'PayoutsForm',
            params: {mid: this.selectedOption.value, tid: this.transaction.id, step: '2'}
          })
        }
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    startStatusPolling() {
      this.isStatusInterval = true;
      this.statusInterval = setInterval(this.fetchSbpStatus, 5000);
    },

    stopPollingAndReset() {
      clearInterval(this.statusInterval);
      this.isStatusInterval = false;
      this.$store.commit('payLender/set_transaction', null);
      this.transactionFio = null;
      this.canConfirm = false;
      this.transactionError = null;
    },
    renderForm(mid) {
      console.log('Start render Form');
      this.newCardTransactionExist = null;
      const midData = this.modules[String(mid)];
      if (midData) {
        this.orderHide = midData.mandatory_info && midData.mandatory_info.order_id_hide;
        this.orderReadonly = midData.mandatory_info && midData.mandatory_info.order_id_auto;
        this.phoneHide = midData.customer_info && midData.customer_info.phone && midData.customer_info.phone.hide;
        this.emailHide = midData.customer_info && midData.customer_info.email && midData.customer_info.email.hide;
        this.orderPlaceholder = this.orderReadonly ? this.$uuid.v4() : 'Введите номер заказа (order_id)';
        this.order_id = this.orderReadonly ? this.$uuid.v4() : '';
        this.phone = midData.customer_info.phone.value ? midData.customer_info.phone.value : '';
        this.email = midData.customer_info.email.user ? this.$store.state.account.mandarin.email : midData.customer_info.email.value ? midData.customer_info.email.value : '';
        this.cardInputMethod = midData.mandatory_info.payouts_mode && midData.mandatory_info.payouts_mode.card && midData.mandatory_info.payouts_mode.card.mode ? midData.mandatory_info.payouts_mode.card.mode : 'mandarin';
        this.sbpVisible = midData.mandatory_info.payouts_mode && midData.mandatory_info.payouts_mode.sbp && midData.mandatory_info.payouts_mode.sbp.enable && midData.mandatory_info.payouts_mode.sbp.enable
        this.docVisible = midData.mandatory_info.site && midData.mandatory_info.site.documents
        this.payoutsMethod = 'card';
        // copy massive
        this.optionalFields = midData.optional_info ? JSON.parse(JSON.stringify(midData.optional_info)) : [];
      }
    },
    async selectChanged() {
      console.log('Start change mid');
      const mid = (this.selectedOption) ? this.selectedOption.value : null;

      if (!mid) return;

      await this.renderForm(mid);
      let is_new = Number(this.$route.params.mid) !== mid;
      if (is_new) this.iframeUrl = false;

      console.log('goto route in selectChanged')
      this.$router.push({
        name: 'PayoutsForm',
        params: {
          mid: mid,
          tid: !is_new ? this.$route.params.tid : null,
          step: !is_new && this.showPayoutStatus ? '2' : null
        }
      });

      this.$store.commit('projectLimits/setCurrentMid', mid);
      await this.$store.dispatch('projectLimits/loadLimits');

      const monthly = this.$store.state.projectLimits.limits.monthly.byMid[mid];

      if (!monthly) this.monthly_limit = {amount: 0, amountLeft: 0};
      else this.monthly_limit = {amount: monthly.amount, amountLeft: monthly.balance.amount_left};

      const daily = this.$store.state.projectLimits.limits.daily.byMid[mid];

      if (!daily) this.daily_limit = {amount: 0, amountLeft: 0};
      else this.daily_limit = {amount: daily.amount, amountLeft: daily.balance.amount_left};

      const transaction = this.$store.state.projectLimits.limits.transaction.byMid[mid];

      this.transaction_limit = transaction ? transaction.amount : 0;
    },

    newPayout() {
      this.iframeUrl = false;
      console.log('goto route in newPayout')
      this.$router.push({name: 'PayoutsForm', params: {mid: this.$route.params.mid}});
      this.$emit('updateHistory');
    },
    async updateTransaction() {
      if (this.transaction && this.transaction.id) {
        if (this.transaction.state === 'bindings' || this.transaction.state === 'processing') {
          const newTid = this.transaction.id;
          console.log('Update transaction ', newTid);
          await this.$store.dispatch('payLender/getTransaction', newTid);
          await this.$store.dispatch('projectLimits/loadLimits');
        } else {
          this.stopTransactionUpdates();
        }
      }
    },
    startTransactionUpdates() {
      console.log('Start check transaction')
      this.transactionUpdateInterval = setInterval(this.updateTransaction, 5000);
    },
    stopTransactionUpdates() {
      if (this.transactionUpdateInterval) {
        clearInterval(this.transactionUpdateInterval);
        this.transactionUpdateInterval = null;
      }
    },
  },
  watch: {
    '$route.params.step': {
      immediate: true,
      async handler(newStep, oldStep) {
        console.log('Changed Step: ', newStep, ' - ', oldStep);
        if (newStep && newStep === '2') {
          this.startTransactionUpdates();
        } else {
          this.stopTransactionUpdates();
        }
      }
    },
    '$store.state.payLender.apiData.results': {
      immediate: true,
      async handler(val) {
        console.log('Store results:', val);
        if (Array.isArray(val) && val.length > 0) {
          await this.get_options();
        } else {
          console.error('Expected an array but got:', val);
          this.options = []; // Сбрасываем options в пустой массив
        }
      }
    },
    '$store.state.account.merchants_name': {
      immediate: true,
      async handler(val) {
        console.log('Store results:', val);
        if (Array.isArray(val) && val.length > 0) {
          await this.get_options();
        }
      }
    },
    '$route.params.mid': {
      immediate: true,
      async handler(newMid, oldMid) {
        console.log('Changed mid: ', newMid, ' - ', oldMid);
        if (newMid) {
          const option = this.options.find(opt => Number(opt.value) === Number(newMid));
          if (option) {
            console.log('Selected mid: ', option.value);
            this.selectedOption = option;
            // await this.selectChanged();
          } else if (this.options.length > 0) {
            this.$toast.info('Мерчант ' + newMid + ' не настроен или деактивирован, выбран ' + oldMid)
            if (oldMid) {
              this.$router.push({name: 'PayoutsForm', params: {mid: oldMid}});
              this.iframeUrl = false;
            } else {
              this.$router.push({name: 'PayoutsForm'});
            }
          }
        }
      }
    },
    '$route.params.tid': {
      immediate: true,
      async handler(newTid, oldTid) {
        console.log('Changed tid: ', newTid, ' - ', oldTid);
        if (newTid && newTid !== oldTid) {
          console.log('Change Tid');
          await this.$store.dispatch('payLender/getTransaction', newTid);
          let transaction = this.$store.state.payLender.apiData.transaction;
          if (transaction) {
            if (Number(transaction.client_merchant) !== Number(this.$route.params.mid)) {
              console.log('goto router incorrect mid');
              this.$router.push({
                name: 'PayoutsForm',
                params: {mid: transaction.client_merchant, tid: newTid, step: '2'}
              });
            }
            this.iframeUrl = true;
            if (transaction.payouts_method) this.payoutsMethod = transaction.payouts_method;
            if (transaction.amount) this.amount = parseFloat(transaction.amount).toFixed(2);
            if (transaction.order_id) this.order_id = transaction.order_id;
            if (transaction.email) this.email = transaction.email;
            if (transaction.phone) this.phone = transaction.phone;
            this.optionalFields = transaction.attributes ? Object.entries(transaction.attributes).map(([key, value]) => {
              return {key: key, value: value};
            }) : [];
            if (this.cardInputMethod !== 'direct' && this.payoutsMethod === 'card') {
              if (transaction && transaction.state === 'bindings') {
                if (!this.scriptLoaded) {
                  console.log('Waiting for script to load');
                  await new Promise(resolve => {
                    const checkInterval = setInterval(() => {
                      if (this.scriptLoaded) {
                        clearInterval(checkInterval);
                        resolve();
                      }
                    }, 100); // Проверяем каждые 100 мс
                  });
                }
                await this.initializeMandarinForm(transaction.secure_js_operation_id);
              } else {
                console.log('goto route in not direct and n_card $route.params.tid')
                this.$router.push({name: 'PayoutsForm', params: {mid: this.$route.params.mid, tid: newTid, step: '2'}});
              }
            } else {
              console.log('goto route in not direct $route.params.tid')
              this.$router.push({name: 'PayoutsForm', params: {mid: this.$route.params.mid, tid: newTid, step: '2'}});
            }
          } else {
            console.log('goto route in not transaction $route.params.tid')
            this.$router.push({name: 'PayoutsForm', params: {mid: this.$route.params.mid}});
          }
        }
      }
    },
    selectedOption(newVal, oldVal) {
      console.log('Changed mid in selectedOption: ', newVal, ' - ', oldVal);
      if (newVal !== oldVal) {
        this.selectChanged();
      }
    },
    payoutsMethod(newVal) {
      console.log('Changed payoutsMethod: ', newVal);
      if (newVal && newVal === 'sbp' && !this.showPayoutStatus && this.showForm2 && this.showForm) this.$store.dispatch('payLender/fetchBanks', {mid: this.$route.params.mid});
    },
    options(newVal) {
      console.log('Watch changed options: ', newVal);
    },
    getFirstPage(newVal, oldVal) {
      console.log('Watch changed getFirstPage: ', newVal, oldVal);
    },
  },
  async beforeMount() {
    console.log('Load CustomPay script');
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = VUE_APP_SCRIPT_CUSTOM_PAY;
      script.onload = () => {
        console.log('CustomPay script loaded');
        this.scriptLoaded = true; // Устанавливаем флаг в true после загрузки скрипта
        resolve();
      };
      script.onerror = () => {
        console.error('Error loading CustomPay script');
        reject();
      };
      document.head.appendChild(script);
    });
  },
  mounted() {

  },
  destroyed() {
    this.stopTransactionUpdates();
  }
}
</script>

<style scoped>
.bold {
  font-weight: 900;
}

.container-form {
  max-width: 840px;
  margin: 20px auto;
}

.form-payout {
  padding: 15px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
}

hr {
  margin: 20px 0;
  border: none;
  border-top: 1px solid #eaeaea;
}

hr::before {
  border: 1px solid red;
}

.limits-info p {
  margin-bottom: 0;
  font-weight: 300;
}

.limits-info p {
  margin-bottom: 0;
}

.min-vh-300 {
  min-height: 300px;
}

.custom-input {
  font-size: 16px;
  font-family: 'Helvetica', serif;
}

.mobile-layer {
  display: none;
}

.card-card.active, .card-sbp.active,
.card-card:hover, .card-sbp:hover {
  border-color: #007bff;
}

.card-card, .card-sbp {
  width: 70px;
  height: 60px;
  border: 1px solid #A6A6A6;
  vertical-align: middle;
  text-align: center;
  padding: 30px 0 0;
}

.card-card img, .card-sbp img {
  margin: -16px 0 0;
}

.mandarinpay-field-card-number {
  flex-grow: 1;
  width: calc(100% - 120px);
}

.mandarinpay-field-card-expiration {
  flex: 0 0 80px;
  width: 80px;
}

.btn-primary.w-100 {
  margin-top: 8px;
  width: 100%;
}

.example-image {
  max-width: 100%;
  margin-top: 20px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 953px) {
  .mobile-layer {
    display: block;
  }
}
</style>
<style>

#form-hosted-pay {
  display: flex;
  flex-wrap: wrap;
}

.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
  background: none;
}

.selected-mid.multiselect .multiselect__tags, .card-card.selected-card, .card-sbp.selected-card {
  border: 2px solid #007bff;
}
</style>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>

<template>
  <d-card class="card-div mb-4 life_merchants">
    <d-card-body class="min-height-500px">
      <Loader v-if="!apiResponseReceived" :size="3" />
      <div v-else>
          <b-form @submit.prevent="onSubmit">
            <div class="back">
              <router-link v-if="!com_import" :to="back">
                <img src="@/assets/images/apple-keyboard-control.png" alt="Go back" width="24" height="24">
                <span class="back-link">Вернуться назад</span>
              </router-link>
              <div v-else class="float-left ml-0">
                <router-link v-if="selected_mid && !item.new" :to="`/payouts/form/${selected_mid}`" class="ps-0 pe-4 py-2">Перейти к выплатам</router-link>
              </div>
              <div class="float-right ml-auto">
                <a href="https://mndr.in/payout_form" target="_blank">Инструкция по настройке и использованию</a>
              </div>
            </div>
            <div v-show="item.new && !com_import" class="settings-block">
              <div class="header">
                <div class="header-text">
                  <h3>Тип модуля и проект
                    <i v-b-tooltip.hover title="У вас есть право создавать модули pay (платежи/выплаты), а так же life (кредиты/рассрочки)">
                      <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                    </i>
                  </h3>
                </div>
              </div>
              <b-form-group class="payment-input">
                <legend>Тип модуля
                </legend>
                <b-form-select v-model="selected" :options="computed_options" @input="toogle_action(selected)"></b-form-select>
              </b-form-group>

              <b-form-group class="payment-input mb-0">
                <legend>Merchant_ID</legend>
                <b-form-select
                  :options="enhancedOptions"
                  v-model="selected_mid"
                  @input="toogle_action_mid(selected_mid)"
                  :disabled="isSelectDisabled"
                ></b-form-select>
              </b-form-group>
            </div>

            <div v-show="(com_import && !item.new) || !com_import">
              <div class="settings-block">
                <div class="header mb-0">
                  <div class="header-text">
                    <h3>Настройка обязательных полей
                      <i v-b-tooltip.hover title="Указанные значения будут предзаполняться в Форме выплаты.">
                        <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                      </i>
                    </h3>
                  </div>
                </div>
                  <b-form-checkbox class="payment-input mt-0" v-model="item.is_active" name="check-button-is_active" switch>{{ item.is_active ? 'Деа' : 'А' }}ктивировать выплаты
                    <i v-b-tooltip.hover title="Выплаты можно деактивировать/активировать">
                      <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                    </i>
                  </b-form-checkbox>
                <div v-if="!item.new" class="merchant mt-0 mb-2">
                  merchant_id: {{ item.merchant_id }}, {{ $t('extend.site_name') }}: {{ name }}
                </div>
                <b-form-checkbox class="payment-input mt-0" color="success" v-model="item.mandatory_info.order_id_auto" switch>Формировать Номер заказа (order_id) автоматически</b-form-checkbox>
                <b-form-checkbox class="payment-input mt-0" color="success" v-model="item.mandatory_info.order_id_hide" switch>Скрыть поле Номер заказа из Формы выплаты</b-form-checkbox>

                <b-form-checkbox class="payment-input mt-0" color="success" v-model="item.customer_info.email.hide" switch>Скрыть поле Email из формы выплаты</b-form-checkbox>
                <b-form-checkbox class="payment-input mt-0" color="success" v-model="item.customer_info.email.user" switch>Использовать email пользователя личного кабинета</b-form-checkbox>
                <b-form-group class="payment-input mb-0">
                  <legend>Значение по умолчанию для поля Email</legend>
                  <b-form-input v-model="item.customer_info.email.value" :value="item.customer_info.email.value" :disabled="item.customer_info.email.user"/>
                </b-form-group>

                <b-form-checkbox class="payment-input mt-0" color="success" v-model="item.customer_info.phone.hide" switch>Скрыть поле Phone из формы выплаты</b-form-checkbox>
                <b-form-group class="payment-input mb-0">
                  <legend>Значение по умолчанию для поля Phone</legend>
                  <b-form-input v-model="item.customer_info.phone.value" :value="item.customer_info.phone.value" />
                </b-form-group>

              </div>

              <div class="settings-block">
                <div class="header">
                  <div class="header-text">
                    <h3>Формирование документов
                      <i v-b-tooltip.hover title="На этапе создания выплаты будет добавлен шаг заполнения необходиммых данных для формирования выбранных документов">
                        <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                      </i>
                    </h3>
                  </div>
                </div>
                <b-form-group class="payment-input mb-0">
                  <b-form-select
                    :options="documentOptions"
                    v-model="item.mandatory_info.site.documents"
                  ></b-form-select>
                </b-form-group>
              </div>

              <div class="settings-block">
                <div class="header">
                  <div class="header-text">
                    <h3>Настройка необязательных полей
                      <i v-b-tooltip.hover title="Указанные значения будут предзаполняться в Форме выплаты.">
                        <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                      </i>
                    </h3>
                  </div>
                </div>
                <b-form-group class="payment-input mb-0">
                  <legend>Название страницы выплат</legend>
                <b-form-input v-model="item.mandatory_info.site.module_name" :value="item.mandatory_info.site.module_name" />
                </b-form-group>
              </div>

              <div class="settings-block">
                <div class="header mb-0">
                  <div class="header-text">
                    <h3>Настройка формирования чеков
                      <i v-b-tooltip.hover title="Настройки формирования чеков">
                        <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                      </i>
                    </h3>
                  </div>
                </div>
                <div class="w-100">
                  <b-form-group class="payment-input mb-0">
                    <b-form-checkbox v-model="item.mandatory_info.fiscalEnable" switch>Формировать чек</b-form-checkbox>
                  </b-form-group>

                  <div v-if="item.mandatory_info.fiscalEnable">
                    <b-form-group class="payment-input mt-4">
                      <legend class="">Наименование:</legend>
                      <b-form-input v-model="item.mandatory_info.fiscalInformation.description" />
                    </b-form-group>

                    <b-form-group class="payment-input">
                      <legend class="">Способ расчета:</legend>
                      <b-form-select v-model="item.mandatory_info.fiscalInformation.calculationMethod" :options="optionscalculationMethod"></b-form-select>
                    </b-form-group>

                    <b-form-group class="payment-input">
                      <legend class="">Предмет расчета:</legend>
                      <b-form-select v-model="item.mandatory_info.fiscalInformation.paymentSubject" :options="optionspaymentSubject"></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div class="settings-block">
                <div class="header mb-0">
                  <div class="header-text">
                    <h3>Настройка дополнительных полей
                      <i v-b-tooltip.hover title="Данные поля передаются по API и отображаются в Форме выплаты, а также в реестрах транзакций">
                        <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                      </i>
                    </h3>
                  </div>
                </div>
                <div>
                  <b-form-checkbox class="payment-input" v-model="ext_field_visible" switch>Добавить дополнительные поля</b-form-checkbox>
                  <div v-show="ext_field_visible">
                    <b-table :fields="fields_optional_info" :items="item.optional_info" class="w-100">
                      <template #cell(key)="data">
                        <b-form-input v-model="data.item.key" :value="data.item.key"/>
                      </template>
                      <template #cell(value)="data">
                        <b-form-input v-model="data.item.value" :value="data.item.value"/>
                      </template>
                      <template #cell(delete)="data">
                        <div class="d-flex justify-content-end align-items-center">
                          <img :src="require('@/assets/images/icon_delete.png')" alt="delete icon" class="lh-36" @click='del(data.index)' />
                        </div>
                      </template>
                    </b-table>
                    <b-button block variant="outline-primary" @click='add()'>Добавить поле</b-button>
                  </div>
                </div>
              </div>

              <div class="settings-block">
                <div class="header mb-0">
                  <div class="header-text">
                    <h3>Ограничение доступа
                      <i v-b-tooltip.hover title="Вы можете настроить каким пользователям будет доступна форма выплат">
                        <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                      </i>
                    </h3>
                  </div>
                </div>
                <b-form-checkbox v-model="item.mandatory_info.security.enable" class="payment-input mt-0 mb-0" name="check-button-is_active_security" switch>
                  Ограничить доступ к форме выплат
                </b-form-checkbox>

                <b-form-group
                  v-if="item.mandatory_info.security.enable"
                  class="payment-input mb-0 mt-3"
                >
                  <legend>Доступ получат ТОЛЬКО выбранные пользователи</legend>
                  <multiselect
                    v-model="multiselectModel"
                    :options="availableUsers"
                    multiple
                    :disabled="loadingUsers"
                    placeholder="Выберите email"
                    label="label"
                    track-by="label"
                  ></multiselect>
                </b-form-group>

              </div>

              <div v-if="!com_import" class="settings-block">
                <LimitsComponent :mid="item.merchant_id" class="w-100" />
              </div>

              <div class="settings-block">
                <div class="header mb-0">
                  <div class="header-text">
                    <h3>Настройка способа выплаты
                      <i v-b-tooltip.hover title="Настройка активна для выплат через ЛК. На данный момент возможна выплата только по номеру карты, с проверкой карты или без. По умолчанию включена проверка номера карты.">
                        <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
                      </i>
                    </h3>
                  </div>
                </div>

                <b-form-checkbox v-model="item.mandatory_info.payouts_mode.card.enable" class="payment-input mt-0 mb-0" name="check-button-is_active_payouts_mode_card" switch disabled>
                  Банковская карта
                </b-form-checkbox>

                <div v-if="item.mandatory_info.payouts_mode.card.enable" class="w-100">
                  <b-form-select
                    :options="cardOptions"
                    v-model="item.mandatory_info.payouts_mode.card.mode"
                    @input=""
                    :disabled="false"
                  ></b-form-select>
                </div>

                <b-form-checkbox v-model="item.mandatory_info.payouts_mode.sbp.enable" class="payment-input mt-0 mb-0" name="check-button-is_active_payouts_mode_card" switch>
                  Система быстрых платежей (СБП)
                </b-form-checkbox>

                <div v-if="item.mandatory_info.payouts_mode.sbp.enable" class="w-100 d-none">
                  <b-form-select
                    :options="sbpOptions"
                    v-model="item.mandatory_info.payouts_mode.sbp.mode"
                    @input=""
                    :disabled="false"
                  ></b-form-select>
                </div>
              </div>

                <hr>

              <div class="row">
                <div class="col-md-6">
                  <b-button :disabled="!enableSaveModule" type="submit" variant="primary" block>Сохранить</b-button>
                </div>
                <div class="col-md-6">
                  <b-button :disabled="!enableSaveModule" variant="primary" block @click="export_to_excel">Загрузить шаблон выплаты по реестрам</b-button>
                </div>
              </div>

            </div>
            <div v-if="com_import && item.new" class="d-flex justify-content-center">
              <d-button type="submit" variant="primary" class="mt-5 px-5">Подключить</d-button>
            </div>

          </b-form>
      </div>
    </d-card-body>
  </d-card>
</template>
<style>

</style>
<script>
import { read, utils, writeFileXLSX } from 'xlsx'
import Multiselect from 'vue-multiselect'
import LimitsComponent from '@/components/ProjectMerchant/limits'
export default {
  props: {
    com_import: {
      type: Boolean,
      default: false
    },
    mid: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      merchants_pay: [],
      merchants_payout: [],
      fields_optional_info: [{key: 'key', label: 'Name (название поля)'}, {key: 'value', label: 'Value (значение поля)'}, {key: 'delete', label: '', class: 'align-center'}],
      options: [{value: 'payout', text: 'Выплаты'}, {value: 'pay', text: 'Приём платежей'}, {value: 'life', text: 'Кредиты/Рассрочки'}],
      cardOptions: [{value: 'mandarin', text: 'Проверка по номеру и сроку действия карты (без СМС)'}, {value: 'mandarin3ds', text: 'Проверка карты при помощи СМС (списание и возврат 1 рубля)'}, {value: 'direct', text: 'Без проверки карты'}],
      sbpOptions: [{value: 'withNameCheck', text: 'С проверкой ФИО'}],
      documentOptions: [{value: null, text: 'Нет'}, {value: 'psa', text: 'Формировать документ ПСА'},],
      selected: null,
      selected_mid: null,
      ext_field_visible: false,
      security_enabled: false,
      optionscalculationMethod: [
        {value: 'FULL_PAY', text: 'Полная оплата, в том числе с учётом аванса (предварительной оплаты) в момент передачи предмета расчёта'},
        {value: 'PREPAY_FULL', text: 'Полная предварительная оплата до момента передачи предмета расчёта'},
        {value: 'PREPAY_PARTIAL', text: 'Частичная предварительная оплата до момента передачи предмета расчёта'},
        {value: 'AVANS', text: 'Аванс'},
        {value: 'PARTIAL_SETTLEMENT_AND_CREDIT', text: 'Частичная оплата предмета расчёта в момент его передачи с последующей оплатой в кредит'},
        {value: 'TRANSFER_ON_CREDIT', text: 'Передача предмета расчёта без его оплаты в момент его передачи с последующей оплатой в кредит'},
        {value: 'CREDIT_PAYMENT', text: 'Оплата предмета расчёта после его передачи с оплатой в кредит (оплата кредита)'}
      ],
      optionspaymentSubject: [
        {value: 'PRODUCT', text: 'Товар'},
        {value: 'SERVICE', text: 'Услуга'},
        {value: 'AGENCY', text: 'Агентское вознаграждение'},
        {value: 'COMPOUND_SUBJECT', text: 'Составной предмет расчета'},
        {value: 'EXCISABLE_PRODUCT', text: 'Подакцизный товар'},
        // {value: 'GAMBLING_RATE', text: 'Ставка азартной игры'},
        // {value: 'GAMBLING_WIN', text: 'Выигрыш азартной игры'},
        // {value: 'INSURANCE_CONTRIBUTIONS', text: 'Страховые взносы'},
        {value: 'JOB', text: 'Работа'},
        // {value: 'LOTTERY_TICKET', text: 'Лотерейный билет'},
        // {value: 'LOTTERY_WIN', text: 'Выигрыш лотереи'},
        // {value: 'NON_OPERATING_INCOME', text: 'Внереализационный доход'},
        {value: 'OTHER_SUBJECT', text: 'Иной предмет расчета'},
        {value: 'PAYMENT', text: 'Платеж'},
        {value: 'PLEDGE', text: 'Залог'},
        // {value: 'PROPERTY_LAW', text: 'Имущественное право'},
        // {value: 'PROVISION_RID', text: 'Представление РИД'},
        {value: 'RESORT_FEE', text: 'Курортный сбор'},
        {value: 'TRADE_FEE', text: 'Торговый сбор'},
      ],
    };
  },
  components:{
    LimitsComponent,
    Multiselect
  },
  computed: {
    multiselectModel: {
      get() {
        return this.item.mandatory_info.security.list.map(email => ({ value: email, label: email }));
      },
      set(value) {
        this.item.mandatory_info.security.list = value.map(obj => obj.value);
      }
    },
    availableUsers() {
      return this.$store.state.payLender.availableUsers;
    },
    loadingUsers() {
      return this.$store.state.payLender.loadingUsers;
    },
    isSelectDisabled() {
      return !this.computedMerchantsPayoutForSelect || this.computedMerchantsPayoutForSelect.length === 0;
    },
    enhancedOptions() {
      let options = [...this.computedMerchantsPayoutForSelect];

      if (this.isSelectDisabled) {
        options.unshift({
          text: "У вас нет доступных merchant_id для создания модуля",
          value: null,
          disabled: true
        });
      } else if (!this.enableSaveModule) {
        options.unshift({
          text: "Выберите Merchant",
          value: null,
          disabled: true
        });
        this.selected_mid = null;
      }

      return options;
    },
    computed_merchants_pay() {
      return this.get_all_merchants.filter(m => !this.merchants_pay.includes(m) && this.addModulePayScope);
    },
    computed_merchants_payout() {
      return this.get_all_merchants.filter(m => !this.merchants_payout.includes(m) && this.addModulePayoutScope);
    },
    computed_options() {
      let options = [...this.options];
      if (!this.addModulePayScope) options = options.filter(item => item.value !== 'pay');
      if (!this.addModulePayoutScope) options = options.filter(item => item.value !== 'payout');
      if (!this.addModuleLifeScope) options = options.filter(item => item.value !== 'life');
      return options;
    },
    type_name: function () {
      return (this.selected === 'payout')?'выплат':'приема платежей';
    },
    computedMerchantsPay() {
      const merchants = this.get_all_merchants;
      const dataTable = this.getDataTable();
      const merchants_pay = [];
      dataTable.forEach(m => {if (m.action === 'pay' && this.addModulePayScope) {merchants_pay.push(m.merchant_id);}});
      return merchants.filter(m => !merchants_pay.includes(m));
    },
    computedMerchantsPayout() {
      const merchants = this.get_all_merchants;
      const dataTable = this.getDataTable();
      const merchants_payout = [];
      dataTable.forEach(m => {if (m.action === 'payout' && this.addModulePayoutScope) {merchants_payout.push(m.merchant_id);}});
      return merchants.filter(m => !merchants_payout.includes(m));
    },
    computedMerchantsPayoutForSelect() {
      const merchants = this.get_all_merchants;
      const merchants_name = this.$store.state.account.merchants_name;
      const dataTable = this.getDataTable();
      const merchants_pay = [];
      dataTable.forEach(m => {
        if (m.action === 'payout' && this.addModulePayScope) {
          merchants_pay.push(m.merchant_id);
        }
      });
      return merchants
        .filter(m => !merchants_pay.includes(m))
        .map(m => ({
          value: m,
          text: m + ' (' + merchants_name[merchants.indexOf(m)] + ')'
        }));
    },
    addModulePayScope: function () {
      return this.$store.state.account.scopesArray.includes('secure_app_pay_module.write');
    },
    addModulePayoutScope: function () {
      return this.$store.state.account.scopesArray.includes('secure_app_payout_module.write');
    },
    addModuleLifeScope: function () {
      return this.$store.state.account.scopesArray.includes('secure_app_life_module.write');
    },
    get_all_merchants () {
      return this.$store.state.account.merchants
    },
    name: function () {
      return this.$store.state.account.merchants_name[this.$store.state.account.merchants.indexOf(Number(this.selected_mid))]
    },
    back () {
        return '/pay_merchant/' + this.$route.params.arg1 + '/' + 'modules/'
    },
    apiResponseReceived () {
      return this.$store.state.pager.apiResponseReceived;
    },
    enableSaveModule () {
      return this.get_all_merchants.includes(Number(this.selected_mid))
    },
    item () {
      let item = {
          new: true,
          is_active: true,
          action: 'payout',
          merchant_id: this.selected_mid,
          mandatory_info: {'order_id_auto': true, 'order_id_hide': true,
            site: {module_name: "Выплата через merchant_id: " + this.selected_mid, documents: null},
            fiscalEnable: false,
            fiscalInformation: {
              calculationMethod: "FULL_PAY",
              paymentSubject: "SERVICE",
              taxationSystem: "Common",
              vat: "None",
              description: "Товар"
            },
            security: {enable: false, list: []},
            payouts_mode: {
              card: {enable: true, mode: 'direct'},
              sbp: {enable: false, mode: 'withNameCheck'},
            }
          },
          customer_info: {phone: {'hide': false, 'value': ""}, email: {'hide': false, 'value': "", 'user': false}},
          optional_info: [{'key': '', 'value': ''}]
      };
      let dataTable = this.getDataTable()
      dataTable.forEach(field => {
        if (field.hasOwnProperty('merchant_id')) {
          if (field.merchant_id === Number(this.selected_mid) && field.action === 'payout') {
              if (field.mandatory_info === null) field.mandatory_info = item.mandatory_info;
              if (!field.mandatory_info.hasOwnProperty('site')) field.mandatory_info['site'] = item.mandatory_info.site;
              if (!field.mandatory_info.hasOwnProperty('fiscalEnable')) {this.$set(field.mandatory_info, 'fiscalEnable', item.mandatory_info.fiscalEnable);}
              if (!field.mandatory_info.hasOwnProperty('fiscalInformation')) field.mandatory_info['fiscalInformation'] = item.mandatory_info.fiscalInformation;
              if (!field.mandatory_info.hasOwnProperty('security')) {this.$set(field.mandatory_info, 'security', item.mandatory_info.security);}
              if (!field.mandatory_info.hasOwnProperty('payouts_mode')) {
                this.$set(field.mandatory_info, 'payouts_mode', item.mandatory_info.payouts_mode);
              } else {
                if (!field.mandatory_info.payouts_mode.hasOwnProperty('sbp')) this.$set(field.mandatory_info.payouts_mode, 'sbp', item.mandatory_info.payouts_mode.sbp);
              }
              if (field.optional_info === null || field.optional_info.length < 1 || field.optional_info[0] === null) field.optional_info = item.optional_info; else this.ext_field_visible = true;
              if (field.customer_info === null) field.customer_info = item.customer_info
              if (field.is_active === null) field.is_active = item.is_active
              item = field;
          }
        }
      });
      if (item.new) {let len = dataTable.push(item); item = dataTable[len-1];}
      return item;
    },
  },
  methods: {
    getDataTable() {
      let activeColumnRow = "modulesAll";
      let responseObject = this.$store.state.payLender.
        displayTable.tableApiUrls[activeColumnRow].responseObject;
      return this.$store.state.payLender.apiData[responseObject];
    },
      onSubmit(evt) {
        if (this.item.customer_info.email.hide === true) {
          if (this.item.customer_info.email.user !== true) {
            if (this.item.customer_info.email.value === '') {
              this.$toast.error("Укажите Email в Обязательных параметрах, если хотите скрыть это поле в форме выплат")
              return
            }
            if (!(/^[a-z0-9_\-\.]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/.test(this.item.customer_info.email.value))) {
              this.$toast.error("Указан невалидный EMail")
              return
            }
          }
        }
        if (this.item.customer_info.phone.hide === true) {
            if (this.item.customer_info.phone.value === '') {
                this.$toast.error("Укажите номер телефона в Обязательных параметрах, если хотите скрыть это поле в форме выплат")
                return
            }
            if (!(/^(\+7)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/.test(this.item.customer_info.phone.value))) {
                this.$toast.error("Указан невалидный номер телефона")
                return
            }
        }
        let newOI = []
        let OI = this.item;
        if (this.ext_field_visible) {
          for (let i in OI.optional_info) {
            if (OI.optional_info[i].key !== '') {
              newOI.push(OI.optional_info[i])
            }
          }
        }
        OI.optional_info = newOI;
        if (OI.mandatory_info.order_id_auto !== true)
            OI.mandatory_info.order_id_hide = false
        this.$store.dispatch('payLender/saveEditPayout',
        {item: OI, back: (this.com_import)?false:this.back, router: this.$router})
      },
      add() {
          this.item.optional_info.push({
                    'key': '',
                    'value': ''
                    })
      },
      del(index) {
          this.item.optional_info.splice(index, 1)
      },
      toogle_action(value) {
        let mid = this.selected_mid
        if (value === 'life') {
          mid = 'new'
        } else {
          let validate_merchants = (value === 'pay')?this.computedMerchantsPay:this.computedMerchantsPayout;
          if (!validate_merchants.includes(mid)) {
                  mid = validate_merchants[0];
                }
        }
          this.$router.push('/pay_merchant/'
                + this.$route.params.arg1
                + '/' + this.$route.params.arg2
                + '/' + value
                + '/' + mid + '/')
      },
      toogle_action_mid(value) {
        if (![undefined, null].includes(value)) {
          let dataTable = this.getDataTable();
          dataTable.pop();
          const querystring = '/pay_merchant/'
            + this.$route.params.arg1
            + '/' + this.$route.params.arg2
            + '/' + this.$route.params.arg3
            + '/' + value + '/';
          console.log('Push to ', querystring);
          this.$router.push(querystring)
          window.location.reload();
        }
      },
      export_to_excel() {
        let data_head = {}
        data_head['order_id'] = ""
        data_head['amount'] = ''
        data_head['card_number'] = ''
        if (!this.item.customer_info.email.hide) data_head['email'] =
                this.item.customer_info.email.value
        if (!this.item.customer_info.phone.hide) data_head['phone'] =
                this.item.customer_info.phone.value
        this.item.optional_info.forEach(op => {
            if (op.key !== '') data_head[op.key] = op.value
        })
        let data = [data_head]
        let ws = utils.json_to_sheet(data)
        let wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        //https://docs.sheetjs.com/docs/api/
        writeFileXLSX(wb, "Template-Payout-" +
                           this.item.merchant_id + ".xlsx", {bookType: 'xlsx'})
      },
  },
  mounted () {
    if (!this.addModulePayoutScope) {
      this.$router.push('/pay_merchant/'
        + this.$route.params.arg1
        + '/' + this.$route.params.arg2
        + '/' + this.$route.params.arg3
        + '/'
      )
    }
    // после установки таблицы апдейтим ее нынешнее значение.
    this.$store.dispatch('pager/updateCurrentTableState', 'default')
    this.$store.dispatch('extend/changedTable')
  },
  created () {
    this.selected = (this.import)?'payout':this.$route.params.arg3;

    if (!this.com_import) {
      this.selected_mid = this.$route.params.mid;
    } else {
      if (!this.mid) console.log('При данном импорте не определен Mid!'); else this.selected_mid = this.mid;
    }

  },
  watch: {
    'item.mandatory_info.security.enable': function(newValue) {
      console.log('Recalculate Users');
      if (newValue) {
        this.$store.dispatch('payLender/fetchAvailableUsers');
      }
    }
  },

}
</script>

<style scoped>
.min-height-500px {
  min-height: 500px;
}
</style>

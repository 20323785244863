<template>
  <div class="position-relative">
    <b-form-input
      :id="id"
      v-model="inputValue"
      @input="onInput"
      @focus="fetchSuggestions"
      :placeholder="placeholder"
    />
    <ul v-if="suggestions.length" class="suggestions-list">
      <li
        v-for="suggestion in suggestions"
        :key="suggestion.value"
        @click="selectSuggestion(suggestion)"
      >
        {{ suggestion.value }}
      </li>
    </ul>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    // Передаем обработчик данных
    handleParsedData: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      inputValue: this.value,
      suggestions: [],
    };
  },
  watch: {
    value(val) {
      this.inputValue = val;
    },
    inputValue(val) {
      this.$emit('input', val);
    },
  },
  created() {
    this.debouncedFetchSuggestions = debounce(this.fetchSuggestions, 500);
  },
  methods: {
    fetchSuggestions() {
      const url = this.getApiUrl();
      const options = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Token ${VUE_APP_DADATA_TOKEN}`,
        },
        body: JSON.stringify({ query: this.inputValue }),
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((result) => {
          this.suggestions = result.suggestions;
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    },
    getApiUrl() {
      switch (this.type) {
        case 'fms_unit':
          return VUE_APP_DADATA_API + 'fms_unit';
        case 'fio':
          return VUE_APP_DADATA_API + 'fio';
        case 'inn':
          return VUE_APP_DADATA_API + 'party';
        case 'address':
          return VUE_APP_DADATA_API + 'address';
        default:
          return VUE_APP_DADATA_API + 'address';
      }
    },
    onInput() {
      if (this.inputValue.length > 2) {
        this.debouncedFetchSuggestions();
      }
    },
    selectSuggestion(suggestion) {
      this.inputValue = suggestion.value;
      this.suggestions = [];

      // Проверяем, есть ли кастомный обработчик для данных
      if (this.handleParsedData) {
        this.handleParsedData(suggestion.data);
      }

      this.$emit('input', suggestion.value);
      this.suggestions = [];
    },
  },
};
</script>

<style scoped>
.suggestions-list {
  position: absolute;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 100;
  width: 100%;
}

.suggestions-list li {
  padding: 5px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}
</style>
